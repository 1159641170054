import { useState, useCallback } from "react";

const useConfirmDialog = () => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [resolvePromise, setResolvePromise] = useState(null);

  const confirm = useCallback((title, message) => {
    setDialogOpen(true);

    return new Promise((resolve) => {
      setResolvePromise(() => resolve);
    });
  }, []);

  const handleClose = useCallback(() => {
    setDialogOpen(false);
  }, []);

  const handleConfirm = useCallback(
    (response) => {
      if (resolvePromise) {
        resolvePromise(response);
      }
      setDialogOpen(false);
    },
    [resolvePromise]
  );

  return {
    dialogOpen,
    confirm,
    handleClose,
    handleConfirm,
  };
};

export default useConfirmDialog;
