import React, { useEffect, useState } from "react";
import { Box, Grid, Button } from "@mui/material";
import "../../Profile/upload.css";
import { portfolioValidation } from "../../utils/validations/vendor.validation";
import Element from "../../components/Form/Element";
import { inputType } from "../../utils/enum";
import { useDispatch, useSelector } from "react-redux";
// import {
//   createPastworkThunk,
//   fetchTechDataThunk,
//   updatePortfolioThunk,
// } from "../../store/slices/portfolio/portfolio.slice";
import { useFormik } from "formik";
import {
  backendOptions,
  budgetOptions,
  clientPersonaOptions,
  cloudOptions,
  databaseOptions,
  frontendOptions,
  platformOptions,
} from "../../utils/options.util";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Icon } from "@iconify/react";
import plusFill from "@iconify/icons-eva/plus-fill";
import {
  createPastworkThunk,
  fetchTechDataThunk,
  fetchVendorProfileDetailsThunk,
  updatePastworkThunk,
} from "../../store/slices/vendor/vendor.slice";
import { updatePortfolioThunk } from "../../store/slices/portfolio/portfolio.slice";
import {
  fetchAllResourceBiFurcationList,
  fetchResourceBiferctionList,
} from "../../utils/server.util";

const INIT_STATE = {
  client_name: "",
  product_code: "",
  description: "",
  client_persona: "",
  project_budget: "",
  meta_tags: "",
  own_source_code: "off",
  key_client: "off",
  links_details: [],
};

const RoleModal = ({
  selectedData,
  handleClose,
  productList,
  page,
  backendOptions,
  frontendOptions,
  databaseOptions,
  cloudOptions,
}) => {
  const dispatch = useDispatch();
  // const { frontend, backend } = useSelector((vendor) => vendor.vendor);

  // console.log("keshav", backendList, frontendList, database, cloud);

  // useEffect(() => {
  //   dispatch(fetchTechDataThunk("frontend"));
  //   dispatch(fetchTechDataThunk("backend"));
  // }, [dispatch]);

  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues: selectedData
      ? {
          // ...selectedData,
          // service: {
          //   ...selectedData.service,
          //   service_id: selectedData.service?._id,
          // },
          // industry: selectedData.industries,
          // verified: selectedData.verified ? "on" : "off",

          client_name: selectedData?.client_name,
          client_persona: selectedData?.client_persona,
          description: selectedData?.description,
          project_budget: selectedData?.project_budget,
          meta_tags: selectedData?.meta_tags,
          own_source_code: selectedData?.own_source_code,
          key_client: selectedData?.key_client,
          links: selectedData?.links,
          product_code: selectedData?.product_code?.id,
          links_details: selectedData?.links_details,
        }
      : INIT_STATE,
    onSubmit: onSubmit,
    validationSchema: portfolioValidation,
  });

  function formatLinks(links) {
    return links?.map((link) => {
      return {
        ...link,
        backend: (function () {
          const obj = backendOptions.find((ele) => ele.value === link.backend);
          return {
            id: obj.value,
            name: obj.label,
          };
        })(),
        frontend: (function () {
          const obj = frontendOptions.find(
            (ele) => ele.value === link.frontend
          );
          return {
            id: obj.value,
            name: obj.label,
          };
        })(),
        database: (function () {
          const obj = databaseOptions.find(
            (ele) => ele.value === link.database
          );
          return {
            id: obj.value,
            name: obj.label,
          };
        })(),
        cloud: (function () {
          const obj = cloudOptions.find((ele) => ele.value === link.cloud);
          return {
            id: obj.value,
            name: obj.label,
          };
        })(),
      };
    });
  }

  function onSubmit(data) {
    // debugger
    // console.log("logs", data);
    if (data.links) {
      data.links = formatLinks(data.links_details);
    }

    data.product_code = productList.find(
      (ele) => ele.value === data?.product_code
    );
    data.product_code = {
      id: data?.product_code?.value || data?.value,
      code: data?.product_code?.label || data?.label,
    };

    if (!data?.product_code.id) data.product_code = selectedData.product_code;

    const payload = {
      data: {
        ...data,
        own_source_code: data.own_source_code === "on" ? true : false,
        key_client: data.key_client === "on" ? true : false,
      },
    };
    if (selectedData) {
      return dispatch(
        // updatePortfolioThunk({
        //   id: selectedData.id,
        //   data: { ...data, verified: data.verified === "on" ? true : false },
        // })
        updatePastworkThunk({
          id: selectedData._id,
          data: { ...payload, verified: data.verified === "on" ? true : false },
        })
      )
        .unwrap()
        .then(dispatch(fetchVendorProfileDetailsThunk()))
        .then(handleClose);
    }
    dispatch(
      createPastworkThunk({
        data: payload,
      })
    )
      .unwrap()
      .then(dispatch(fetchVendorProfileDetailsThunk()))
      .then(handleClose);
  }

  const addInputRow = async () => {
    setFieldValue("links_details", [
      ...values.links_details,
      {
        link: "",
        backend: "",
        frontend: "",
        database: "",
        cloud: "",
        demo_link: "",
        link_creds: "",
        platform: "",
      },
    ]);
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };

  return (
    <>
      <Box>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            // if(e.key === 'Enter' || e.keyCode === 13){
            //   e.preventDefault();
            //   return;
            // }
            handleSubmit();
          }}
          onKeyPress={handleKeyPress}
        >
          <Grid container spacing={0} p={2}>
            <Grid item md={12} pb={0}>
              <Element
                // label="Client Name"
                label={
                  <>
                    Client Name
                    <span style={{ color: "red" }}>*</span>
                  </>
                }
                inputProps={{
                  type: "text",
                  placeholder: "Enter Client Name",
                  onChange: handleChange,
                  onBlur: handleBlur,
                  name: "client_name",
                }}
                eletype={inputType.input}
                value={values.client_name}
                errorText={touched.client_name && errors.client_name}
              />
            </Grid>
            <Grid item md={12} pb={0}>
              <Element
                // label="Product Code"
                label={
                  <>
                    Product Code
                    <span style={{ color: "red" }}>*</span>
                  </>
                }
                inputProps={{
                  type: "select",
                  placeholder: "Enter Product Code",
                  onChange: handleChange,
                  onBlur: handleBlur,
                  name: "product_code",
                }}
                eletype={inputType.select}
                options={productList}
                value={values.product_code}
                errorText={
                  touched.product_code &&
                  // touched.product_code?.id &&
                  errors.product_code
                  // errors.product_code?.id
                }
              />
            </Grid>
            <Grid item md={12} pb={0}>
              <Element
                label="Description"
                inputProps={{
                  type: "text",
                  placeholder: "Enter Description",
                  onChange: handleChange,
                  onBlur: handleBlur,
                  name: "description",
                }}
                eletype={inputType.input}
                value={values.description}
                errorText={touched.description && errors.description}
              />
            </Grid>
            <Grid item md={12}>
              <Element
                label="Client Persona"
                inputProps={{
                  name: "client_persona",
                  onChange: handleChange,
                  onBlur: handleBlur,
                }}
                value={values.client_persona}
                options={clientPersonaOptions}
                errorText={touched.client_persona && errors.client_persona}
                eletype={inputType.select}
                icons={
                  <ArrowDropDownIcon
                    sx={{ position: "absolute", right: 20, bottom: 24 }}
                  />
                }
              />
            </Grid>

            <Grid item md={12} pb={0}>
              <Element
                label="Project Budget"
                inputProps={{
                  type: "text",
                  placeholder: "Enter Project Budget",
                  onChange: handleChange,
                  onBlur: handleBlur,
                  name: "project_budget",
                }}
                eletype={inputType.select}
                options={budgetOptions}
                value={values.project_budget}
              />
            </Grid>

            <Grid item md={12} pb={3}>
              <Element
                label="Meta Tags"
                inputProps={{
                  placeholder: "Enter Meta Tags",
                  type: "text",
                  onChange: handleChange,
                  onBlur: handleBlur,
                  name: "meta_tags",
                }}
                eletype={inputType.chip}
                value={values.meta_tags}
              />
            </Grid>
            <Grid item md={12} sx={{ display: "flex", alignItems: "center" }}>
              <Element
                eletype={inputType.switch}
                label="If you own Source Code?"
                inputProps={{
                  name: "own_source_code",
                  onChange: handleChange,
                  handleBlur: handleBlur,
                }}
                value={values.own_source_code}
              />
            </Grid>

            <Grid item md={12} sx={{ display: "flex", alignItems: "center" }}>
              <Element
                eletype={inputType.switch}
                label="Key Client"
                inputProps={{
                  name: "key_client",
                  onChange: handleChange,
                  handleBlur: handleBlur,
                }}
                value={values.key_client}
              />
            </Grid>
            <Grid item md={12} pb={2}>
              <Button
                size="large"
                variant="contained"
                startIcon={<Icon icon={plusFill} />}
                onClick={addInputRow}
              >
                Add Link
              </Button>
            </Grid>

            {values?.links_details?.map((item, index) => (
              <div
                style={{
                  // border: '1px solid gray',
                  width: "100%",
                  padding: "20px 40px",
                  borderRadius: "8px",
                  boxShadow: "0px 0px 10px gray",
                  margin: "30px auto",
                }}
              >
                <Grid item md={12} pb={2}>
                  <Element
                    label="Link"
                    inputProps={{
                      type: "text",
                      placeholder: "Enter Link",
                      onChange: handleChange,
                      onBlur: handleBlur,
                      name: `links_details.${index}.link`,
                    }}
                    eletype={inputType.url}
                    value={item.link}
                  />
                </Grid>

                <Grid item md={12}>
                  <Element
                    label="Platform"
                    inputProps={{
                      name: `links_details.${index}.platform`,
                      onChange: handleChange,
                      onBlur: handleBlur,
                    }}
                    value={item.platform}
                    options={platformOptions}
                    eletype={inputType.select}
                    icons={
                      <ArrowDropDownIcon
                        sx={{ position: "absolute", right: 20, bottom: 24 }}
                      />
                    }
                  />
                </Grid>

                <Grid item md={12}>
                  <Element
                    label="Backend"
                    inputProps={{
                      name: `links_details.${index}.backend`,
                      onChange: handleChange,
                      onBlur: handleBlur,
                    }}
                    value={item.backend}
                    options={backendOptions}
                    errorText={
                      touched.links_details &&
                      touched.links_details[index] &&
                      errors.links_details &&
                      errors.links_details[index]?.backend
                    }
                    eletype={inputType.select}
                    icons={
                      <ArrowDropDownIcon
                        sx={{ position: "absolute", right: 20, bottom: 24 }}
                      />
                    }
                  />
                </Grid>

                <Grid item md={12}>
                  <Element
                    label="Frontend"
                    inputProps={{
                      name: `links_details.${index}.frontend`,
                      onChange: handleChange,
                      onBlur: handleBlur,
                    }}
                    value={item.frontend}
                    options={frontendOptions}
                    errorText={
                      touched.links_details &&
                      touched.links_details[index] &&
                      errors.links_details &&
                      errors.links_details[index]?.frontend
                    }
                    eletype={inputType.select}
                    icons={
                      <ArrowDropDownIcon
                        sx={{ position: "absolute", right: 20, bottom: 24 }}
                      />
                    }
                  />
                </Grid>

                <Grid item md={12}>
                  <Element
                    label="Database"
                    inputProps={{
                      name: `links_details.${index}.database`,
                      onChange: handleChange,
                      onBlur: handleBlur,
                    }}
                    value={item.database}
                    options={databaseOptions}
                    errorText={
                      touched.links_details &&
                      touched.links_details[index] &&
                      errors.links_details &&
                      errors.links_details[index]?.database
                    }
                    eletype={inputType.select}
                    icons={
                      <ArrowDropDownIcon
                        sx={{ position: "absolute", right: 20, bottom: 24 }}
                      />
                    }
                  />
                </Grid>

                <Grid item md={12}>
                  <Element
                    label="Cloud"
                    inputProps={{
                      name: `links_details.${index}.cloud`,
                      onChange: handleChange,
                      onBlur: handleBlur,
                    }}
                    value={item.cloud}
                    options={cloudOptions}
                    errorText={
                      touched.links_details &&
                      touched.links_details[index] &&
                      errors.links_details &&
                      errors.links_details[index]?.cloud
                    }
                    eletype={inputType.select}
                    icons={
                      <ArrowDropDownIcon
                        sx={{ position: "absolute", right: 20, bottom: 24 }}
                      />
                    }
                  />
                </Grid>

                <Grid item md={12} pb={2}>
                  <Element
                    label="Demo Link"
                    inputProps={{
                      type: "text",
                      placeholder: "Enter Demo link",
                      onChange: handleChange,
                      onBlur: handleBlur,
                      name: `links_details.${index}.demo_link`,
                    }}
                    eletype={inputType.url}
                    value={item.demo_link}
                  />
                </Grid>

                <Grid item md={12} pb={2}>
                  <Element
                    label="Link Credentials"
                    inputProps={{
                      type: "text",
                      placeholder: "Enter Credentials link",
                      onChange: handleChange,
                      onBlur: handleBlur,
                      name: `links_details.${index}.link_creds`,
                    }}
                    eletype={inputType.url}
                    value={item.link_creds}
                  />
                </Grid>
              </div>
            ))}

            <Grid item md={12} pb={2}>
              <Button size="large" type="submit" variant="contained">
                Save
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </>
  );
};

export default RoleModal;
