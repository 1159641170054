import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Grid, Button, Stack, IconButton } from "@mui/material";
import { inputType } from "../../utils/enum";
import {
  createPOCThunk,
  fetchVendorProfileDetailsThunk,
  updatePOCThunk,
} from "../../store/slices/vendor/vendor.slice";
import {
  personalityPOC,
  ageRangeOfPOC,
  escalationLevel,
} from "../../utils/options.util";
import Element from "../../components/Form/Element";
import { escalationLevelValidations } from "../../utils/validations/vendor.validation";
import MobileInput from "../../components/Form/MobileInput";
import useForm from "../../hooks/useForm";
import "react-phone-input-2/lib/style.css";
import { fetchAllLanguageThunk } from "../../store/slices/language/language.slice";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { Formik, Form, Field, FieldArray, useFormik } from "formik";

export default function PointOfContactModal({ props, closeModal, page }) {
  const dispatch = useDispatch();
  // const languageData = useSelector((data) => data.language.languagedata);
  // const [languageItem, setLanguageItem] = useState(() => {
  //   if (props.language_proficiency) {
  //     if (props.language_proficiency?.length === 0) return [0];
  //     var arr = [];
  //     var idx = 0;
  //     props.language_proficiency.forEach(() => {
  //       arr.push(idx);
  //       idx++;
  //     });
  //     return arr;
  //   }
  //   return [0];
  // });

  useEffect(() => {
    dispatch(fetchAllLanguageThunk());
  }, [dispatch]);

  function onSubmit(data) {
    const newData = {
      ...data,
      code: data?.mobile?.country_code,
      mobile_number: data?.mobile?.mobile_number,
    };
    delete newData?.mobile;

    if (!props) {
      dispatch(createPOCThunk({ data: newData, page: page }))
        .unwrap()
        .then(closeModal)
        .then(dispatch(fetchVendorProfileDetailsThunk()));
    } else {
      dispatch(
        updatePOCThunk({
          id: props._id,
          data: newData,
        })
      )
        .unwrap()
        .then(closeModal)
        .then(dispatch(fetchVendorProfileDetailsThunk()));
    }
  }

  const { values, errors, touched, handleChange, handleBlur, handleSubmit } =
    useFormik({
      initialValues: {
        // language_proficiency: props?.language_proficiency
        //   ? props.language_proficiency.map((ele) => ({
        //       ...ele,
        //       language: ele._id,
        //     }))
        //   : [],
        name: props.name == "" ? "" : props.name,
        // last_name: props.last_name == "" ? "" : props.last_name,
        email: props.email == "" ? "" : props.email,
        // age_range: props.age_range == "" ? "" : props.age_range,
        // escalation_level:
        //   props.escalation_level == "" ? "" : props.escalation_level,
        designation: props.designation == "" ? "" : props.designation,
        // communication_rating:
        //   props.communication_rating == "" ? "" : props.communication_rating,
        // knowledge_rating:
        //   props.knowledge_rating == "" ? "" : props.knowledge_rating,
        mobile: {
          country_code: props?.code,
          mobile_number: props?.mobile_number,
        },
        // mobile: props.mobile_number
        //   ? {
        //       country_code: props.code,
        //       mobile_number: props.mobile_number,
        //     }
        //   : {},
        // closure: props.closure ? "on" : "off",
      },
      onSubmit: onSubmit,
      validationSchema: escalationLevelValidations,
    });

  // function fncRemoveRow(index) {
  //   if (languageItem.length === 1) return;
  //   const items = [...languageItem];
  //   const idx = items.findIndex((ele) => ele === index);
  //   items.splice(idx, 1);
  //   setLanguageItem(items);
  //   const currentValue = [...values.language_proficiency];
  //   currentValue.splice(idx, 1);
  //   handleChange({
  //     target: {
  //       name: "language_proficiency",
  //       value: currentValue,
  //     },
  //   });
  // }

  // function isLanguagePresent(element) {
  //   return values.language_proficiency.find(
  //     (ele) => ele.language === element._id
  //   );
  // }

  // const langData = useMemo(() => {
  //   return languageData
  //     .filter((ele) => {
  //       return !isLanguagePresent(ele);
  //     })
  //     .map((ele) => {
  //       return {
  //         label: ele.name,
  //         value: ele._id,
  //       };
  //     });
  // }, [languageData, values.language_proficiency]);

  // function getLanguageById(id) {
  //   return languageData.find((ele) => ele._id === id);
  // }

  // const refOptions = useMemo(() => {
  //   return languageData.map((ele) => {
  //     return {
  //       label: ele.name,
  //       value: ele._id,
  //     };
  //   });
  // }, [languageData]);

  return (
    <>
      <Box>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2} p={2}>
            <Grid item md={6} pb={0}>
              <Element
                // label="Name"
                label={
                  <>
                    Name <span style={{ color: "red" }}>*</span>
                  </>
                }
                inputProps={{
                  placeholder: "Enter First Name",
                  type: "text",
                  onChange: handleChange,
                  onBlur: handleBlur,
                  name: "name",
                }}
                eletype={inputType.input}
                value={values.name}
                errorText={touched.name && errors.name}
              />
            </Grid>
            {/* <Grid item md={6} pb={0}>
              <Element
                label="Last Name"
                inputProps={{
                  placeholder: "Enter Last Name",
                  type: "text",
                  onChange: handleChange,
                  onBlur: handleBlur,
                  name: "last_name",
                }}
                eletype={inputType.input}
                value={values.last_name}
                errorText={touched.last_name && errors.last_name}
              />
            </Grid> */}
            <Grid item md={6} pb={0}>
              <MobileInput
                // label="Contact Number"
                label={
                  <>
                    Contact Number <span style={{ color: "red" }}>*</span>
                  </>
                }
                name="mobile"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.mobile}
                errorText={touched.mobile && errors.mobile?.mobile_number}
              />
            </Grid>
            <Grid item md={6} pb={0}>
              <Element
                // label="Email Id"
                label={
                  <>
                    Email Id <span style={{ color: "red" }}>*</span>
                  </>
                }
                inputProps={{
                  placeholder: "Enter Email ID",
                  type: "text",
                  onChange: handleChange,
                  onBlur: handleBlur,
                  name: "email",
                }}
                eletype={inputType.input}
                value={values.email}
                errorText={touched.email && errors.email}
              />
            </Grid>
            <Grid item md={6} pb={0}>
              <Element
                // label="Designation"
                label={
                  <>
                    Designation <span style={{ color: "red" }}>*</span>
                  </>
                }
                inputProps={{
                  placeholder: "Enter  Designation",
                  type: "text",
                  onChange: handleChange,
                  onBlur: handleBlur,
                  name: "designation",
                }}
                eletype={inputType.input}
                value={values.designation}
                errorText={touched.designation && errors.designation}
              />
            </Grid>
            {/* <Grid item md={6} pb={0}>
              <Element
                label="Age Range"
                inputProps={{
                  name: "age_range",
                  onChange: handleChange,
                  onBlur: handleBlur,
                }}
                value={values.age_range}
                options={ageRangeOfPOC}
                errorText={touched.age_range && errors.age_range}
                eletype={inputType.select}
              />
            </Grid> */}
            {/* <Grid item md={6} pb={0}>
              <Element
                label="Escalation Level"
                inputProps={{
                  name: "escalation_level",
                  onChange: handleChange,
                  onBlur: handleBlur,
                }}
                value={values.escalation_level}
                options={escalationLevel}
                errorText={touched.escalation_level && errors.escalation_level}
                eletype={inputType.select}
              />
            </Grid> */}
            <Grid item md={6} pb={0}></Grid>

            {/* {languageItem.map((id, index) => (
              <>
                <Stack
                  direction="row"
                  justifyContent="space-evenly"
                  alignItems="center"
                  spacing={0}
                >
                  <input
                    type="hidden"
                    name={`language_proficiency.${id}._id`}
                    value={langData[index]}
                  />
                  <Grid item md={6} p={1} pb={0}>
                    <Element
                      label="Language"
                      inputProps={{
                        name: `language_proficiency.${id}.language`,
                        onChange: handleChange,
                        onBlur: handleBlur,
                      }}
                      value={values.language_proficiency[id]?.language}
                      options={langData}
                      eletype={inputType.select}
                      errorText={touched.language && errors.language}
                      refOptions={refOptions}
                    />
                  </Grid>
                  <Grid item md={4} p={2} pb={0}>
                    <Ratings
                      label="Proficiency"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.language_proficiency[id]?.rating}
                      name={`language_proficiency.${id}.rating`}
                      errorText={touched.proficiency && errors.rating}
                    />
                  </Grid>
                  <div
                    style={{
                      position: "relative",
                      right: "-40px",
                      top: "10px",
                    }}
                  >
                    <IconButton>
                      <AddIcon onClick={fncAddRow} />
                    </IconButton>
                    <IconButton>
                      <DeleteIcon onClick={() => fncRemoveRow(id)} />
                    </IconButton>
                  </div>
                </Stack>
              </>
            ))}

            <Grid item md={6} p={3}>
              <Ratings
                label="Communication Rating"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.communication_rating}
                name="communication_rating"
                errorText={
                  touched.communication_rating && errors.communication_rating
                }
              />
            </Grid>
            <Grid item md={6} p={3}>
              <Ratings
                label="Knowledge Rating"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.knowledge_rating}
                name="knowledge_rating"
                errorText={touched.knowledge_rating && errors.knowledge_rating}
              />
            </Grid>

            <Grid item md={4}>
              <Element
                eletype={inputType.switch}
                label="Closure"
                inputProps={{
                  name: "closure",
                  onChange: handleChange,
                  handleBlur: handleBlur,
                }}
                value={values.closure}
                errorText={touched.closure && errors.closure}
              />
            </Grid> */}
            <Grid item md={12} p={3} sx={{ textAlign: "center" }}>
              <Button type="submit" variant="contained">
                Submit
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </>
  );
}
