import React, { useEffect, useState, useMemo } from "react";
import Drawer from "@mui/material/Drawer";
import Filter from "../../components/Filter/Filter";
import ListSearchbar from "../../components/Common/ListSearchbar";
import ListHead from "../../components/Common/ListHead";
import Scrollbar from "../../components/Common/Scrollbar";
import {
  Card,
  Stack,
  Button,
  Container,
  Typography,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TablePagination,
  Modal,
  Box,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { capitalCase } from "../../components/Common/change-case";
import _ from "lodash";
import {
  fetchFinanceApprovalThunk,
  fetchFinanceApprovalApproveThunk,
  fetchFinanceApprovalRejectThunk,
} from "../../store/slices/financeApproval/financeApproval.slice";
import Element from "../../components/Form/Element";
import { inputType } from "../../utils/enum";
import { Icon } from "@iconify/react";
import dounloadIcon from "@iconify/icons-eva/download-fill";
import usePermissions from "../../hooks/usePermission";
import systemPermissions, {
  actions,
} from "../../utils/global/permissions.global";

const getIcon = (name) => <Icon icon={name} width={22} height={22} />;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "fit-content;",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "10px",
  p: 4,
  height: "650px",
  overflow: "scroll",
};

const TABLE_HEAD = [
  { id: "id", label: "ID", alignRight: false },
  { id: "brand_name", label: "Brand Name", alignRight: false },
  { id: "register_name ", label: "Register Name", alignRight: false },
  { id: "company_type", label: "Company Type", alignRight: false },
  { id: "phone", label: "Phone", alignRight: false },
  { id: "view", label: "View", alignRight: false },
  // { id: "register_address", label: "Register Address", alignRight: false },
  // { id: "billing_address", label: "Billing Address", alignRight: false },
  // { id: "bankDetails", label: "Bank Details", alignRight: false },
  // { id: "pan", label: "Pan", alignRight: false },
  // { id: "pan_file", label: "Pan File", alignRight: false },
  // { id: "gst", label: "GST", alignRight: false },
  // { id: "gst_file", label: "GST File", alignRight: false },
  // { id: "aadhar", label: "Aadhar", alignRight: false },
  // { id: "aadhar_file", label: "Aadhar File", alignRight: false },
  // { id: "mase", label: "MSME", alignRight: false },
  // { id: "mase_file", label: "MSME File", alignRight: false },
  // { id: "nda", label: "NDA File", alignRight: false },
];

function FinanceApproval() {
  const [showDrawer, setShowDrawer] = useState(false);
  const dispatch = useDispatch();
  const { financeApprovalData, totalFinanceData } = useSelector(
    ({ financeApproval }) => financeApproval
  );
  const [page, setPage] = React.useState(0);
  const [rowsPerPage] = React.useState(10);
  const [rowData, setRowData] = useState({});
  const [rejectReason, setRejectReason] = useState("");
  const [showReasonBox, setShowReasonBox] = useState(false);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [openApprove, setOpenApprove] = React.useState(false);
  const handleOpenApprove = () => setOpenApprove(true);
  const handleCloseApprove = () => setOpenApprove(false);

  const userPerm = usePermissions();

  useEffect(() => {
    dispatch(fetchFinanceApprovalThunk(1));
  }, []);

  function handleRowClick(row) {
    setRowData(row);
    handleOpen();
  }

  const handleChangePage = (_, newPage) => {
    dispatch(fetchFinanceApprovalThunk(newPage + 1));
    setPage(newPage);
  };

  function financeApprovalApi(data) {
    if (showReasonBox === "approve") {
      const payload = {
        vendor_bus_profile_id: data,
      };
      dispatch(fetchFinanceApprovalApproveThunk(payload));
      handleCloseApprove();
      handleClose();
    } else {
      const payload = {
        vendor_bus_profile_id: data,
        unapproved_reason: rejectReason,
      };
      dispatch(fetchFinanceApprovalRejectThunk(payload));
      handleCloseApprove();
      handleClose();
    }
  }

  function financeShowReason(data, type) {
    setShowReasonBox(type);
    handleOpenApprove();
  }

  return (
    <>
      <Container>
        <Drawer
          anchor="right"
          open={showDrawer}
          onClose={() => setShowDrawer(false)}
          PaperProps={{
            style: { width: "400px" },
          }}
        >
          <Filter />
        </Drawer>

        <Stack
          mb={2}
          justifyContent="space-between"
          direction="row"
          alignItems="center"
        >
          <Typography variant="h4" gutterBottom>
            FinanceApproval
          </Typography>
        </Stack>

        <Card>
          <Scrollbar>
            {financeApprovalData?.vendors?.length > 0 ? (
              <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                  <ListHead
                    headLabel={TABLE_HEAD}
                    rowCount={financeApprovalData?.vendors?.length}
                    styles={{ backgroundColor: "rgb(243, 246, 248)" }}
                  />
                  <TableBody>
                    {financeApprovalData?.vendors?.map((row, indx) => {
                      const {
                        aadhaar_number,
                        account_holder_name,
                        account_number,
                        accounts_verified,
                        bank_branch,
                        bank_name,
                        brand_name,
                        company_type,
                        country_code,
                        created_at,
                        created_by,
                        description,
                        gst_number,
                        id,
                        ifsc_code,
                        incorporation_number,
                        mobile_number,
                        msme_reg_number,
                        number_of_clients,
                        number_of_employees,
                        org_id,
                        pan_number,
                        profile_creation_source,
                        profile_id,
                        profile_stage,
                        rating,
                        registered_business_name,
                        slug,
                        status,
                        turnover,
                        updated_at,
                        updated_by,
                        verified,
                        verified_mail_sent,
                        website,
                        year_of_establishment,
                        zoho_id,
                        zoho_id_uae,
                        addresses,
                        aadhaar_file,
                        pan_file,
                        nda_file,
                        gst_file,
                        msme_reg_file,
                      } = row;

                      return (
                        <TableRow
                          onClick={() => handleRowClick(row)}
                          hover
                          key={id}
                          sx={{ cursor: "pointer", zIndex: "" }}
                        >
                          <TableCell>
                            <Typography noWrap sx={{ fontSize: "15px" }}>
                              {id}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography noWrap sx={{ fontSize: "15px" }}>
                              {capitalCase(brand_name?.[0])}
                            </Typography>
                          </TableCell>

                          <TableCell>
                            <Typography noWrap sx={{ fontSize: "15px" }}>
                              {capitalCase(registered_business_name?.[0])}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography noWrap sx={{ fontSize: "15px" }}>
                              {capitalCase(company_type?.[0])}
                            </Typography>
                          </TableCell>

                          <TableCell>
                            <Typography noWrap sx={{ fontSize: "15px" }}>
                              {mobile_number?.[0]}
                            </Typography>
                          </TableCell>

                          {userPerm.hasAccess(
                            systemPermissions["financeApprovalListPage.view"],
                            actions.read
                          ) ? (
                            <TableCell>
                              <Typography
                                onClick={(e) => handleRowClick(row)}
                                noWrap
                                sx={{ fontSize: "15px" }}
                              >
                                <Button
                                  sx={{
                                    backgroundColor: "whitesmoke",
                                    color: "#005bbe",
                                  }}
                                >
                                  View
                                </Button>
                              </Typography>
                            </TableCell>
                          ) : (
                            <TableCell>-</TableCell>
                          )}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                  <TableFooter>
                    <TableRow>
                      <TablePagination
                        count={totalFinanceData}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        rowsPerPageOptions={false}
                      />
                    </TableRow>
                  </TableFooter>
                </Table>
              </TableContainer>
            ) : (
              <Box sx={{ p: "40px", textAlign: "center" }}>No Data Exist</Box>
            )}
          </Scrollbar>
        </Card>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography
              sx={{
                fontSize: "15px",
                textDecoration: "line-through",
                color: "gray",
              }}
              id="modal-modal-title"
              variant="h6"
              component="h2"
            >
              {rowData?.brand_name?.[1]}
            </Typography>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              {rowData?.brand_name?.[0]}
            </Typography>
            <Box
              sx={{
                display: "flex",
                gap: "40px",
                mt: "20px",
                justifyContent: "space-between",
              }}
            >
              <Box>
                <Typography
                  sx={{
                    color: "#212b36",
                    fontSize: "0.9rem",
                    fontWeight: "bold",
                    mb: "0.5rem",
                    mt: "1rem",
                  }}
                >
                  Register Name
                </Typography>
                <Typography
                  sx={{
                    fontSize: "15px",
                    textDecoration: "line-through",
                    color: "gray",
                  }}
                >
                  {rowData?.registered_business_name?.[1]}
                </Typography>
                <Typography>
                  {rowData?.registered_business_name?.[0]}
                </Typography>
              </Box>
              <Box>
                <Typography
                  sx={{
                    color: "#212b36",
                    fontSize: "0.9rem",
                    fontWeight: "bold",
                    mb: "0.5rem",
                    mt: "1rem",
                  }}
                >
                  Company Type
                </Typography>
                <Typography
                  sx={{
                    fontSize: "12px",
                    textDecoration: "line-through",
                    color: "gray",
                  }}
                >
                  {rowData?.company_type?.[1]}
                </Typography>
                <Typography>{rowData?.company_type?.[0]}</Typography>
              </Box>
              <Box>
                <Typography
                  sx={{
                    color: "#212b36",
                    fontSize: "0.9rem",
                    fontWeight: "bold",
                    mb: "0.5rem",
                    mt: "1rem",
                  }}
                >
                  Phone Number
                </Typography>
                <Typography
                  sx={{
                    fontSize: "12px",
                    textDecoration: "line-through",
                    color: "gray",
                  }}
                >
                  {rowData?.mobile_number?.[1]}
                </Typography>
                <Typography>{rowData?.mobile_number?.[0]}</Typography>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                gap: "40px",
                mt: "20px",
                justifyContent: "space-between",
              }}
            >
              <>
                {rowData?.address_line1?.[0] ? (
                  <Box>
                    <Typography
                      sx={{
                        color: "#212b36",
                        fontSize: "0.9rem",
                        fontWeight: "bold",
                        mb: "0.5rem",
                        mt: "1rem",
                      }}
                    >
                      Address
                    </Typography>
                    <Box sx={{ mb: "10px" }}>
                      <Typography
                        sx={{
                          fontSize: "12px",
                          textDecoration: "line-through",
                          color: "gray",
                        }}
                      >
                        {rowData?.address_line1?.[1]}
                      </Typography>
                      <Typography>
                        {rowData?.address_line1?.[0]
                          ? rowData?.address_line1?.[0]
                          : "-"}
                      </Typography>
                    </Box>
                    {/* <Box sx={{ mb: "10px" }}>
                      <Typography
                        sx={{
                          fontSize: "12px",
                          textDecoration: "line-through",
                          color: "gray",
                        }}
                      >
                        {rowData?.address_line2?.[1]}
                      </Typography>
                      <Typography>
                        {rowData?.address_line2?.[0]
                          ? rowData?.address_line2?.[0]
                          : "-"}
                      </Typography>
                    </Box> */}
                    {/* <Box sx={{ mb: "10px" }}>
                      <Typography  sx={{
                          fontSize: "12px",
                          textDecoration: "line-through",
                          color: "gray",
                        }}>{rowData?.address_line3?.[1]}</Typography>
                      <Typography>
                        {rowData?.address_line3?.[0]
                          ? rowData?.address_line3?.[0]
                          : "-"}
                      </Typography>
                    </Box> */}
                    <Box
                      sx={{
                        mb: "10px",
                        display: "flex",
                        gap: "10px",
                        alignItems: "end",
                      }}
                    >
                      <Box
                        sx={{
                          width: "70px",
                        }}
                      >
                        City:{" "}
                      </Box>
                      <Typography
                        sx={{ display: "flex", flexDirection: "column" }}
                      >
                        <Box
                          sx={{
                            fontSize: "12px",
                            textDecoration: "line-through",
                            color: "gray",
                          }}
                        >
                          {" "}
                          {rowData?.city?.[1]}
                        </Box>
                        <Box>
                          {" "}
                          {rowData?.city?.[0] ? rowData?.city?.[0] : "-"}
                        </Box>
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        mb: "10px",
                        display: "flex",
                        gap: "10px",
                        alignItems: "end",
                      }}
                    >
                      <Box
                        sx={{
                          width: "70px",
                        }}
                      >
                        State:{" "}
                      </Box>
                      <Typography
                        sx={{ display: "flex", flexDirection: "column" }}
                      >
                        <Box
                          sx={{
                            fontSize: "12px",
                            textDecoration: "line-through",
                            color: "gray",
                          }}
                        >
                          {" "}
                          {rowData?.state?.[1]}
                        </Box>
                        <Box>
                          {" "}
                          {rowData?.state?.[0] ? rowData?.state?.[0] : "-"}
                        </Box>
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        mb: "10px",
                        display: "flex",
                        gap: "10px",
                        alignItems: "end",
                      }}
                    >
                      <Box
                        sx={{
                          width: "70px",
                        }}
                      >
                        Country:{" "}
                      </Box>
                      <Typography
                        sx={{ display: "flex", flexDirection: "column" }}
                      >
                        <Box
                          sx={{
                            fontSize: "12px",
                            textDecoration: "line-through",
                            color: "gray",
                          }}
                        >
                          {" "}
                          {rowData?.country?.[1]}
                        </Box>
                        <Box>
                          {" "}
                          {rowData?.country?.[0] ? rowData?.country?.[0] : "-"}
                        </Box>
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        mb: "10px",
                        display: "flex",
                        gap: "10px",
                        alignItems: "end",
                      }}
                    >
                      <Box
                        sx={{
                          width: "70px",
                        }}
                      >
                        Zip Code:{" "}
                      </Box>
                      <Typography
                        sx={{ display: "flex", flexDirection: "column" }}
                      >
                        <Box
                          sx={{
                            fontSize: "12px",
                            textDecoration: "line-through",
                            color: "gray",
                          }}
                        >
                          {" "}
                          {rowData?.zip_code?.[1]}
                        </Box>
                        <Box>
                          {" "}
                          {rowData?.zip_code?.[0]
                            ? rowData?.zip_code?.[0]
                            : "-"}
                        </Box>
                      </Typography>
                    </Box>
                  </Box>
                ) : (
                  <></>
                )}
              </>
              <>
                <Box>
                  <Typography
                    sx={{
                      color: "#212b36",
                      fontSize: "0.9rem",
                      fontWeight: "bold",
                      mb: "0.5rem",
                      mt: "1rem",
                    }}
                  >
                    BankDetails
                  </Typography>
                  <Box
                    sx={{
                      mb: "10px",
                      display: "flex",
                      gap: "10px",
                      alignItems: "end",
                    }}
                  >
                    <Box
                      sx={{
                        width: "140px",
                      }}
                    >
                      Bank Name:{" "}
                    </Box>
                    <Typography
                      sx={{ display: "flex", flexDirection: "column" }}
                    >
                      <Box
                        sx={{
                          fontSize: "12px",
                          textDecoration: "line-through",
                          color: "gray",
                        }}
                      >
                        {rowData?.bank_name?.[1]}
                      </Box>
                      <Box>
                        {" "}
                        {rowData?.bank_name?.[0]
                          ? rowData?.bank_name?.[0]
                          : "-"}
                      </Box>
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      mb: "10px",
                      display: "flex",
                      gap: "10px",
                      alignItems: "end",
                    }}
                  >
                    <Box
                      sx={{
                        width: "140px",
                      }}
                    >
                      Account Number:{" "}
                    </Box>
                    <Typography
                      sx={{ display: "flex", flexDirection: "column" }}
                    >
                      <Box
                        sx={{
                          fontSize: "12px",
                          textDecoration: "line-through",
                          color: "gray",
                        }}
                      >
                        {rowData?.account_number?.[1]}
                      </Box>
                      <Box>
                        {rowData?.account_number?.[0]
                          ? rowData?.account_number?.[0]
                          : "-"}
                      </Box>
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      mb: "10px",
                      display: "flex",
                      gap: "10px",
                      alignItems: "end",
                    }}
                  >
                    <Box
                      sx={{
                        width: "140px",
                      }}
                    >
                      IFSC code:{" "}
                    </Box>
                    <Typography
                      sx={{ display: "flex", flexDirection: "column" }}
                    >
                      <Box
                        sx={{
                          fontSize: "12px",
                          textDecoration: "line-through",
                          color: "gray",
                        }}
                      >
                        {rowData?.ifsc_code?.[1]}
                      </Box>
                      <Box>
                        {rowData?.ifsc_code?.[0]
                          ? rowData?.ifsc_code?.[0]
                          : "-"}
                      </Box>
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      mb: "10px",
                      display: "flex",
                      gap: "10px",
                      alignItems: "end",
                    }}
                  >
                    <Box
                      sx={{
                        width: "140px",
                      }}
                    >
                      Branch Name:{" "}
                    </Box>
                    <Typography
                      sx={{ display: "flex", flexDirection: "column" }}
                    >
                      <Box
                        sx={{
                          fontSize: "12px",
                          textDecoration: "line-through",
                          color: "gray",
                        }}
                      >
                        {rowData?.bank_branch?.[1]}
                      </Box>
                      <Box>
                        {rowData?.bank_branch?.[0]
                          ? rowData?.bank_branch?.[0]
                          : "-"}
                      </Box>
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      mb: "10px",
                      display: "flex",
                      gap: "10px",
                      alignItems: "end",
                    }}
                  >
                    <Box
                      sx={{
                        width: "140px",
                        display: "flex",
                        gap: "10px",
                        alignItems: "baseline",
                      }}
                    >
                      <span>Cancel Cheque: </span>
                      {rowData?.cancelled_cheque_file?.[0] ? (
                        <a
                          target="_black"
                          href={rowData?.cancelled_cheque_file?.[0]}
                        >
                          <Icon
                            style={{ cursor: "pointer" }}
                            icon={dounloadIcon}
                            width={15}
                            height={15}
                          />
                        </a>
                      ) : (
                        <></>
                      )}
                    </Box>
                    <Typography
                      sx={{ display: "flex", flexDirection: "column" }}
                    >
                      <Box
                        sx={{
                          fontSize: "12px",
                          textDecoration: "line-through",
                          color: "gray",
                        }}
                      >
                        {" "}
                        {rowData?.cheque?.[1]}
                      </Box>
                      <Box> {rowData?.cheque?.[0] && rowData?.cheque?.[0]}</Box>
                    </Typography>
                  </Box>
                </Box>
              </>
            </Box>
            <Box
              sx={{
                display: "flex",
                gap: "40px",
                mt: "20px",
                justifyContent: "space-between",
              }}
            >
              <Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#212b36",
                      fontSize: "0.9rem",
                      fontWeight: "bold",
                      mb: "0.5rem",
                      mt: "0.5rem",
                    }}
                  >
                    Pan Number
                  </Typography>
                  {rowData?.pan_file?.[0] ? (
                    <a target="_black" href={rowData?.pan_file?.[0]}>
                      <Icon
                        style={{ cursor: "pointer" }}
                        icon={dounloadIcon}
                        width={15}
                        height={15}
                      />
                    </a>
                  ) : (
                    <></>
                  )}
                </Box>
                <Typography
                  sx={{
                    fontSize: "12px",
                    textDecoration: "line-through",
                    color: "gray",
                  }}
                >
                  {rowData?.pan_number?.[1]}
                </Typography>
                <Typography>
                  {rowData?.pan_number?.[0] ? rowData?.pan_number?.[0] : "-"}
                </Typography>
              </Box>
              <Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#212b36",
                      fontSize: "0.9rem",
                      fontWeight: "bold",
                      mb: "0.5rem",
                      mt: "0.5rem",
                    }}
                  >
                    GST Number
                  </Typography>
                  {rowData?.gst_file?.[0] ? (
                    <a target="_black" href={rowData?.gst_file?.[0]}>
                      <Icon
                        style={{ cursor: "pointer" }}
                        icon={dounloadIcon}
                        width={15}
                        height={15}
                      />
                    </a>
                  ) : (
                    <></>
                  )}
                </Box>
                <Typography
                  sx={{
                    fontSize: "12px",
                    textDecoration: "line-through",
                    color: "gray",
                  }}
                >
                  {rowData?.gst_number?.[1]}
                </Typography>
                <Typography>
                  {rowData?.gst_number?.[0] ? rowData?.gst_number?.[0] : "-"}
                </Typography>
              </Box>
              <Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#212b36",
                      fontSize: "0.9rem",
                      fontWeight: "bold",
                      mb: "0.5rem",
                      mt: "0.5rem",
                    }}
                  >
                    Aadhar Number
                  </Typography>
                  {rowData?.aadhaar_file?.[0] ? (
                    <a target="_black" href={rowData?.aadhaar_file?.[0]}>
                      <Icon
                        style={{ cursor: "pointer" }}
                        icon={dounloadIcon}
                        width={15}
                        height={15}
                      />
                    </a>
                  ) : (
                    <></>
                  )}
                </Box>
                <Typography
                  sx={{
                    fontSize: "12px",
                    textDecoration: "line-through",
                    color: "gray",
                  }}
                >
                  {rowData?.aadhaar_number?.[1]}
                </Typography>
                <Typography>
                  {rowData?.aadhaar_number?.[0]
                    ? rowData?.aadhaar_number?.[0]
                    : "-"}
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                gap: "40px",
                mt: "20px",
                justifyContent: "start",
              }}
            >
              <Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#212b36",
                      fontSize: "0.9rem",
                      fontWeight: "bold",
                      mb: "0.5rem",
                      mt: "0.5rem",
                    }}
                  >
                    MSME Number
                  </Typography>
                  {rowData?.msme_reg_file?.[0] ? (
                    <a target="_black" href={rowData?.msme_reg_file?.[0]}>
                      <Icon
                        style={{ cursor: "pointer" }}
                        icon={dounloadIcon}
                        width={15}
                        height={15}
                      />
                    </a>
                  ) : (
                    <></>
                  )}
                </Box>
                <Typography
                  sx={{
                    fontSize: "12px",
                    textDecoration: "line-through",
                    color: "gray",
                  }}
                >
                  {rowData?.msme_reg_number?.[1]}
                </Typography>
                <Typography>
                  {rowData?.msme_reg_number?.[0]
                    ? rowData?.msme_reg_number?.[0]
                    : "-"}
                </Typography>
              </Box>
              <Box>
                <Typography
                  sx={{
                    color: "#212b36",
                    fontSize: "0.9rem",
                    fontWeight: "bold",
                    mb: "0.5rem",
                    mt: "0.5rem",
                  }}
                >
                  Download NDA{" "}
                  {rowData?.nda_file?.[0] ? (
                    <a target="_black" href={rowData?.nda_file?.[0]}>
                      <Icon
                        style={{ cursor: "pointer" }}
                        icon={dounloadIcon}
                        width={15}
                        height={15}
                      />
                    </a>
                  ) : (
                    <></>
                  )}
                </Typography>
                <Typography>
                  {rowData?.nda?.[0]
                    ? rowData?.nda?.[0]
                    : rowData?.nda_filename?.[0]
                    ? rowData?.nda_filename[0]
                    : "-"}
                </Typography>
              </Box>
            </Box>
            <>
              <Box
                sx={{
                  display: "flex",
                  gap: "30px",
                  mt: "20px",
                  justifyContent: "space-start",
                }}
              >
                {userPerm.hasAccess(
                  systemPermissions["financeApprovalItem.approve"],
                  actions.create
                ) && (
                  <Button
                    onClick={() => financeShowReason(rowData.id, "approve")}
                    variant="contained"
                    color="success"
                  >
                    Approve
                  </Button>
                )}
                {userPerm.hasAccess(
                  systemPermissions["financeApprovalItem.reject"],
                  actions.create
                ) && (
                  <Button
                    onClick={() => financeShowReason(rowData.id, "reject")}
                    variant="contained"
                    color="error"
                  >
                    Reject
                  </Button>
                )}
              </Box>
              <Modal
                open={openApprove}
                onClose={handleCloseApprove}
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description"
              >
                <Box sx={{ ...style, width: "400px", height: "230px" }}>
                  <h4 id="child-modal-title">
                    {showReasonBox === "approve"
                      ? "Do you want to approve ?"
                      : "Enter the reason for reject"}
                  </h4>
                  <Box>
                    {showReasonBox === "reject" ? (
                      <>
                        <Element
                          eletype={inputType.textarea}
                          inputProps={{
                            placeholder: "Enter Vendor Pan",
                            type: "text",
                            name: "reject_reason",
                            onChange: (e) => setRejectReason(e.target.value),
                          }}
                        />
                        <Button
                          onClick={() => financeApprovalApi(rowData.id)}
                          disabled={
                            rejectReason === "" || rejectReason == undefined
                          }
                          variant="contained"
                          color="success"
                        >
                          Submit
                        </Button>
                      </>
                    ) : (
                      <Button
                        sx={{ mt: "20px" }}
                        onClick={() => financeApprovalApi(rowData.id)}
                        variant="contained"
                        color="success"
                      >
                        Submit
                      </Button>
                    )}
                  </Box>
                </Box>
              </Modal>
            </>
          </Box>
        </Modal>
      </Container>
    </>
  );
}

export default FinanceApproval;
