import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAllCvsByInternalThunk,
  fetchAllCvsByPartnerThunk,
  fetchRaLeadByIdThunk,
  getAllglobalRepoCvsThunk,
  getAllPersonalRepoCvsThunk,
  postAttachEmbCvsThunk,
} from "../../store/slices/ra/ra.slice";
import { useParams } from "react-router-dom";
import {
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Card,
  Typography,
  Button,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import RaCvTable from "./RaCvTable";
import SearchNotFound from "../../components/NotFound/SearchNotFound";
import { Table, Tabs } from "antd";
import MuiModal from "../../components/Modal/MuiModal";
import UploadCv from "./UploadCv";
import { capitalCase } from "../../utils/change-case";

const accordionSummaryStyle = {
  backgroundColor: "#f0f0f0",
  color: "black",
  fontWeight: "bold",
  "& .MuiIconButton-root": {
    color: "black",
  },
};

const accordionStyle = {
  marginBottom: "10px",
};

const personal_columns = [
  {
    title: "Candidate Name",
    dataIndex: "applicant_name",
  },
  {
    title: "Skill ",
    dataIndex: "skill_name",
    render: (text) => <span>{text ?? " - "}</span>,
  },
  {
    title: "Budget",
    dataIndex: "vendor_budget",
    render: (text) => <span>{text ?? " - "}</span>,
  },
  {
    title: "Candidate CV",
    dataIndex: "cv_file",
    render: (cv) => {
      return cv ? (
        <a href={cv.url} target="_blank" rel="noopener noreferrer">
          {cv.filename}
        </a>
      ) : (
        <span> - </span>
      );
    },
  },
  {
    title: "Experience",
    dataIndex: "year_of_exp",
    render: (text) => <span>{Number(text).toFixed(0) ?? " - "}</span>,
  },
  {
    title: "CV Status",
    dataIndex: "emb_applicant_status",
    render: (status) => {
      let cv_status = "";

      if (status.includes("cv")) {
        let new_arr = [];
        let statusArr = status.split("_");
        for (let i = 0; i < statusArr.length; i++) {
          if (statusArr[i].includes("cv")) new_arr.push("CV");
          else new_arr.push(capitalCase(statusArr[i]));
        }
        cv_status = new_arr.join(" ");
      } else {
        cv_status = capitalCase(status.split("_").join(" "));
      }
      return (
        <span className="p-1 rounded-lg px-3 font-semibold ">
          {status ? (
            <span className="text-green-500 " style={{ color: "green" }}>
              {/* {status.split("_").join(" ")} */}
              {cv_status}
            </span>
          ) : (
            " - "
          )}
        </span>
      );
    },
  },
];

const global_columns = [
  {
    title: "Candidate Name",
    dataIndex: "applicant_name",
  },
  {
    title: "Vendor Name",
    dataIndex: "vendor_name",
    render: (text) => <span>{text ?? " - "}</span>,
  },
  {
    title: "Skill ",
    dataIndex: "skill_name",
    render: (text) => <span>{text ?? " - "}</span>,
  },
  {
    title: "Budget",
    dataIndex: "vendor_budget",
    render: (text) => <span>{text ?? " - "}</span>,
  },
  {
    title: "Candidate CV",
    dataIndex: "cv_file",
    render: (cv) => {
      return cv ? (
        <a href={cv.url} target="_blank" rel="noopener noreferrer">
          {cv.filename}
        </a>
      ) : (
        <span> - </span>
      );
    },
  },
  {
    title: "Experience",
    dataIndex: "year_of_exp",
    render: (text) => <span>{text ?? " - "}</span>,
  },
  {
    title: "CV Status",
    dataIndex: "emb_applicant_status",
    render: (status) => {
      let cv_status = "";

      if (status.includes("cv")) {
        let new_arr = [];
        let statusArr = status.split("_");
        for (let i = 0; i < statusArr.length; i++) {
          if (statusArr[i].includes("cv")) new_arr.push("CV");
          else new_arr.push(capitalCase(statusArr[i]));
        }
        cv_status = new_arr.join(" ");
      } else {
        cv_status = capitalCase(status.split("_").join(" "));
      }

      return (
        <span className="p-1 rounded-lg px-3 font-semibold ">
          {status ? (
            <span className="text-green-500 " style={{ color: "green" }}>
              {/* {status.split("_").join(" ")} */}
              {cv_status}
            </span>
          ) : (
            " - "
          )}
        </span>
      );
    },
  },
];

const RaCvs = () => {
  const { partner_cvs, lead, internal_cvs, global_cvs, personal_cvs } =
    useSelector(({ ra }) => ra);
  const [formattedPartnerCVs, setFormattedPartnerCVs] = useState([]);
  const [formattedInternalCVs, setFormattedInternalCVs] = useState([]);
  const [attachCvModalOpen, setAttachCvModalOpen] = useState(false);
  const [addCvModalOpen, setAddCvModalOpen] = useState(false);
  const [selectedCvs, setSelectedCvs] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const params = useParams();
  const dispatch = useDispatch();

  const [activeTab, setActiveTab] = useState("1");

  const handleTabChange = (key) => {
    setActiveTab(key);
  };

  console.log(personal_cvs, global_cvs);

  useEffect(() => {
    dispatch(fetchRaLeadByIdThunk(params.leadId));
    dispatch(fetchAllCvsByPartnerThunk(params.leadId));
    dispatch(fetchAllCvsByInternalThunk(params.leadId));
    dispatch(getAllPersonalRepoCvsThunk());
    dispatch(getAllglobalRepoCvsThunk());
  }, [dispatch, params.leadId]);

  useEffect(() => {
    setSelectedRowKeys([]);
    setSelectedCvs([]);
  }, [activeTab]);

  useEffect(() => {
    if (partner_cvs) {
      const formattedCVs = Object.keys(partner_cvs).map((key) => ({
        name: key,
        ...partner_cvs[key],
      }));
      setFormattedPartnerCVs(formattedCVs);
    }
  }, [partner_cvs]);

  useEffect(() => {
    if (internal_cvs) {
      const formattedCVs = Object.keys(internal_cvs).map((key) => ({
        name: key,
        ...internal_cvs[key],
      }));
      setFormattedInternalCVs(formattedCVs);
    }
  }, [internal_cvs]);

  const handleAttachModalOk = () => {
    console.log("Selected CVs:", selectedCvs);

    if (activeTab === "1") {
      // Call API for Personal Repo
      console.log("Submitting to Personal Repo API...");
      const payload = {
        repo_name: "local",
        bench_strength_ids: selectedCvs.map((item) => item.id),
      };

      dispatch(postAttachEmbCvsThunk({ payload, id: params.leadId }))
        .unwrap()
        .then(dispatch(fetchAllCvsByInternalThunk(params.leadId)));
    } else if (activeTab === "2") {
      // Call API for Global Repo
      console.log("Submitting to Global Repo API...");
      const payload = {
        repo_name: "global",
        bench_strength_ids: selectedCvs.map((item) => item.bench_strength_id),
      };
      console.log(payload);
      dispatch(postAttachEmbCvsThunk({ payload, id: params.leadId }))
        .unwrap()
        .then(dispatch(fetchAllCvsByInternalThunk(params.leadId)));
    }

    handleAttachModalCancel();
  };

  const handleAttachModalCancel = () => {
    setSelectedCvs([]);
    setSelectedRowKeys([]);
    setActiveTab("1");
    setAttachCvModalOpen(false);
  };

  const handleAddModalClose = () => {
    setAddCvModalOpen(false);
  };

  const handleRowSelectionChange = (newSelectedRowKeys, selectedRows) => {
    setSelectedRowKeys(newSelectedRowKeys);
    setSelectedCvs(selectedRows);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: handleRowSelectionChange,
  };

  const renderPersonalCvTables = (repoData) => (
    <>
      <Table
        rowSelection={rowSelection}
        columns={personal_columns}
        dataSource={repoData}
        pagination={false}
        rowKey="id"
      />
      <Box display="flex" justifyContent="flex-end" mt={2}>
        <Button variant="contained" onClick={handleAttachModalOk}>
          Submit
        </Button>
      </Box>
    </>
  );
  const renderGlobalCvTables = (repoData) => (
    <>
      <Table
        rowSelection={rowSelection}
        columns={global_columns}
        dataSource={repoData}
        pagination={false}
        rowKey="id"
      />
      <Box display="flex" justifyContent="flex-end" mt={2}>
        <Button variant="contained" onClick={handleAttachModalOk}>
          Submit
        </Button>
      </Box>
    </>
  );

  const transformedResponse = global_cvs.map((item) => {
    return {
      ...item,
      candidate_name: item.applicant_name,
      vendor_name: item.vendor_name,
      tech_stack: item.skill_name,
      cost_per_month: item.vendor_budget,
      experience: item.year_of_exp,
    };
  });
  // .map(
  //   ({ applicant_name, skill_name, vendor_budget, year_of_exp, ...rest }) =>
  //     rest
  // );

  const transformCVFile = (data) => {
    return data
      .map((item) => {
        return {
          ...item,
          cv_file: {
            filename: item.cv_filename,
            url: item.cv_url || item.cv_file_url,
          },
        };
      })
      .map(({ cv_filename, cv_url, ...rest }) => rest);
  };

  const items = [
    {
      key: "1",
      label: "Personal Repo",
      children: renderPersonalCvTables(transformCVFile(personal_cvs)),
    },
    {
      key: "2",
      label: "Global Repo",
      children: renderGlobalCvTables(transformCVFile(transformedResponse)),
    },
  ];

  return (
    <>
      <Box width="100%" display="flex" justifyContent="flex-end" gap={1} mb={4}>
        <Button variant="contained" onClick={() => setAddCvModalOpen(true)}>
          Add New CV
        </Button>
        <Button variant="outlined" onClick={() => setAttachCvModalOpen(true)}>
          Attach CV
        </Button>
      </Box>
      <Card sx={{ padding: "1rem" }}>
        <Box>
          {formattedInternalCVs.length > 0 && (
            <>
              <Box display="flex">
                <Typography variant="h5" my={2}>
                  EMB CV:
                </Typography>
              </Box>
              {formattedInternalCVs.map((cvs) => (
                <Accordion key={cvs.name} sx={accordionStyle}>
                  <AccordionSummary
                    sx={accordionSummaryStyle}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "98%",
                      }}
                    >
                      <span>{cvs.name}</span>
                      <span>{cvs.applicants.length} CV</span>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    <RaCvTable cvs={cvs.applicants} />
                  </AccordionDetails>
                </Accordion>
              ))}
            </>
          )}
        </Box>
        <Box>
          {formattedPartnerCVs.length > 0 ? (
            <>
              {formattedInternalCVs.length > 0 && (
                <>
                  <hr style={{ margin: "1rem 0", marginTop: "2rem" }} />
                  <Typography variant="h5" my={2}>
                    Partner CV:
                  </Typography>
                </>
              )}
              {formattedPartnerCVs.map((cvs) => (
                <Accordion key={cvs.name} sx={accordionStyle}>
                  <AccordionSummary
                    sx={accordionSummaryStyle}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2-content"
                    id="panel2-header"
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "98%",
                      }}
                    >
                      <span>{cvs.name}</span>
                      <span>{cvs.applicants.length} CV</span>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    <RaCvTable cvs={cvs.applicants} />
                  </AccordionDetails>
                </Accordion>
              ))}
            </>
          ) : (
            formattedInternalCVs.length < 1 && (
              <h4 style={{ fontStyle: "italic", color: "gray" }}>
                No Cvs has been uploaded yet!
              </h4>
            )
          )}
        </Box>
      </Card>

      {/* ADD CV MODAL  */}
      <MuiModal
        title="Add CV"
        open={addCvModalOpen}
        handleClose={handleAddModalClose}
        width={700}
      >
        <UploadCv
          sx={{ marginTop: "20px" }}
          hidePortfolioModalCheck={handleAddModalClose}
          raId={params.leadId}
        />
      </MuiModal>

      {/* ATTACH CV MODAL */}
      <MuiModal
        title="Attach CV"
        open={attachCvModalOpen}
        handleSubmit={handleAttachModalOk}
        handleClose={handleAttachModalCancel}
        width={1000}
      >
        <Box>
          <Tabs defaultActiveKey="1" items={items} onChange={handleTabChange} />
        </Box>
      </MuiModal>
    </>
  );
};

export default RaCvs;
