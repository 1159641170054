import React, { useEffect } from "react";
import DatePicker from "react-datepicker";
import { useStyles } from "./Form.theme";
import { inputType } from "../../utils/enum";
import Input from "./Input";
import Dropdown from "./Dropdown";
import FileInput from "./FileInput";
import Switch from "./Switch";
import NumberInput from "./NumberInput";
import UrlInput from "./UrlInput";
import CheckboxList from "./CheckBoxGroup";
import ChipInput from "./ChipInput";
import MultiSelectDropdown from "./MultiSelectDropdown";

function Element({
  eletype,
  name,
  label,
  errorText,
  inputProps,
  options,
  value,
  styles,
  placeholder,
  onChange,
  disabled,
  fileUploadWithoutUrl,
  resetTheField,
  minDateShow,
  ...rest
}) {
  const classes = useStyles();
  function renderInput() {
    return (
      <Input
        label={label}
        errorText={errorText}
        inputProps={inputProps}
        value={value}
        styles={styles}
        placeholder={placeholder}
        {...rest}
        disabled={disabled}
      />
    );
  }

  function renderUrlInput() {
    return (
      <UrlInput
        label={label}
        errorText={errorText}
        inputProps={inputProps}
        value={value}
        style={styles}
        {...rest}
      />
    );
  }

  useEffect(() => {
    if (!value) {
      inputProps?.onChange({
        target: {
          name: inputProps.name,
          value: "",
        },
      });
    }
  }, []);

  function renderTextarea() {
    return (
      <div className={classes.formGroup} style={styles}>
        <label className={classes.formLabel}>{label}</label>
        <textarea
          className={classes.textareaFormControl}
          type="text"
          {...inputProps}
          value={value}
          {...rest}
          autoComplete="off"
          placeholder={placeholder}
        />
        {errorText && (
          <span className={classes.formErrorStyle}>{errorText}</span>
        )}
      </div>
    );
  }

  function renderSelect() {
    return (
      <Dropdown
        label={label}
        errorText={errorText}
        options={options}
        inputProps={inputProps}
        value={value}
        styles={styles}
        disabled={disabled}
        resetTheField={resetTheField}
        {...rest}
      />
    );
  }

  function renderMultiSelect() {
    return (
      <MultiSelectDropdown
        label={label}
        errorText={errorText}
        options={options}
        {...inputProps}
        value={value}
        styles={styles}
        disabled={disabled}
        resetTheField={resetTheField}
        {...rest}
      />
    );
  }

  function renderAutoComplete() {
    return (
      <div className={classes.formGroup}>
        <label className={classes.formLabel}>Give your project name here</label>
        <input className={classes.formControl} type="text" autoComplete="off" />
      </div>
    );
  }

  function renderDatePicker() {
    return (
      <>
        <label className={classes.formLabel}>{label}</label>
        <DatePicker
          className={classes.formControl}
          selected={value}
          minDate={minDateShow ? new Date(minDateShow) : null}
          {...inputProps}
          {...rest}
          onChange={(date) => {
            inputProps.onChange({
              target: {
                name: inputProps.name,
                value: date,
              },
            });
          }}
          placeholderText={inputProps.placeholder}
          // minDate={new Date("1950-01-01")}
          // maxDate={new Date()}
        />
        {errorText && (
          <span className={classes.formErrorStyle}>{errorText}</span>
        )}
      </>
    );
  }

  function renderFileInput() {
    return (
      <FileInput
        label={label}
        {...inputProps}
        errorText={errorText}
        value={value}
        {...rest}
        disabled={disabled}
        fileUploadWithoutUrl={fileUploadWithoutUrl}
      />
    );
  }

  function renderNumberInput() {
    return (
      <NumberInput
        label={label}
        errorText={errorText}
        inputProps={inputProps}
        value={value}
        {...rest}
      />
    );
  }

  function renderCheckBoxGroup() {
    return (
      <CheckboxList
        label={label}
        {...inputProps}
        errorText={errorText}
        value={value}
        options={options}
        onChange={onChange}
        name={name}
      />
    );
  }

  function renderChip() {
    return (
      // <div className={classes.formGroup}>
      //   <label className={classes.formLabel}>Give your project name here</label>
      //   <input className={classes.formControl} type="text" autoComplete="off" />
      // </div>
      <ChipInput label={label} {...inputProps} value={value} />
    );
  }

  return (
    <>
      {eletype === inputType.input && renderInput()}
      {eletype === inputType.select && renderSelect()}
      {eletype === inputType.multiselect && renderMultiSelect()}
      {eletype === inputType.number && renderNumberInput()}
      {eletype === inputType.textarea && renderTextarea()}
      {eletype === inputType.autocomplete && renderAutoComplete()}
      {eletype === inputType.date && renderDatePicker()}
      {eletype === inputType.file && renderFileInput()}
      {eletype === inputType.url && renderUrlInput()}
      {eletype === inputType.chip && renderChip()}
      {eletype === inputType.checkboxgroup && renderCheckBoxGroup()}
      {eletype === inputType.switch && (
        <Switch label={label} {...inputProps} {...rest} value={value} />
      )}
    </>
  );
}

export default Element;
