import React, { useEffect } from "react";
import { useParams, Outlet, useNavigate } from "react-router-dom";
import { globalCvs, personalCvs, vetOverview } from "../../utils/constant";
import {
  Container,
  Grid,
  Box,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import CustomTabs from "../../components/Common/Tabs";

function VettingRepoDetailsTabs() {
  const labels = [
    // {
    //   label: "Overview",
    //   value: vetOverview,
    //   icon: "/static/icons/overview.svg",
    //   activeIcon: "/static/icons/overview_active.svg",
    // },
    {
      label: "Global Repo.",
      value: globalCvs,
      icon: "/static/icons/expertise.svg",
      activeIcon: "/static/icons/expertise_active.svg",
    },
    {
      label: "Personal Repo.",
      value: personalCvs,
      icon: "/static/icons/expertise.svg",
      activeIcon: "/static/icons/expertise_active.svg",
    },
  ];

  return (
    <>
      <Grid>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <CustomTabs labels={labels} />
        </Box>
        {/* {isLoading && (
            <TabPanel value={selectedTab} index={0}>
              <div style={{ position: "relative" }}>
                <Loader bg="rgba(255,255,255)" height="60vh" />
              </div>
            </TabPanel>
          )} */}
        <div style={{ marginBottom: "20px" }}></div>
        <Outlet />
      </Grid>
    </>
  );
}

export default VettingRepoDetailsTabs;
