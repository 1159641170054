/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import {
  Button,
  Box,
  Card,
  Stack,
  Table,
  Chip,
  TableContainer,
  TableBody,
  TableRow,
  TableCell,
  TableFooter,
  TablePagination,
} from "@mui/material";
import { css } from "@emotion/css";
import plusFill from "@iconify/icons-eva/plus-fill";
import { Icon } from "@iconify/react";
import Modal from "../components/Modal/Modal";
import RoleModal from "../components/Modal/RoleModal";
import ListHead from "../components/Common/ListHead";
import Scrollbar from "../components/Common/Scrollbar";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";
import {
  deleteProductCodeThunk,
  fetchAllProductCodeThunk,
  searchVendorSericesThunk,
} from "../../src/store/slices/vendorServices/vendorservice.slice";
import useBusinessId from "../hooks/useBusinessId";
import DeleteAction from "../components/Common/DeleteAction";
import { serviceType } from "../utils/enum";
import withBusinessId from "../hoc/withBusinessId";
import SearchNotFound from "../../src/components/NotFound/SearchNotFound";
import _ from "lodash";
import usePermissions from "../hooks/usePermission";
import systemPermissions, { actions } from "../utils/global/permissions.global";

const TABLE_HEAD = [
  { label: "Service", id: "service_name", alignRight: false },
  { label: "Min Price", id: "min_price", alignRight: false },
  { label: "Industry", id: "industry", alignRight: false },
  { label: "Sub Industry", id: "sub_industry", alignRight: false },
  { label: "Delivery Class", id: "delivery_class", alignRight: false },
  { label: "Product Code", id: "product_code", alignRight: false },
  { id: "action", label: "Action ", alignRight: false },
];

function ServiceOffered() {
  const [showModal, setShowModal] = useState(false);
  const [showCustomService, setCustomService] = useState(false);

  // --new for pagination starts here---
  const [search, setSearch] = useState("");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage] = React.useState(10);

  const userPer = usePermissions();

  const handleChangePage = (_, newPage) => {
    if (search) {
      setPage(newPage);
      return dispatch(
        searchVendorSericesThunk({
          search,
          page: newPage + 1,
        })
      );
    }
    dispatch(fetchAllProductCodeThunk(newPage + 1));
    setPage(newPage);
  };

  const handleOpen = () => {
    setShowModal(true);
  };
  const handleClose = () => {
    setShowModal(false);
    setSelectedData(null);
  };
  const [selectedData, setSelectedData] = useState(null);

  const dispatch = useDispatch();
  const businessId = useBusinessId();

  const { productCodeList, totalServices } = useSelector(
    (vendorservices) => vendorservices.vendorservices
  );

  console.log("data", productCodeList, totalServices);

  useEffect(() => {
    if (page !== 0) {
      dispatch(fetchAllProductCodeThunk(page));
      setPage(parseInt(page - 1));
    }
  }, [productCodeList.length === 0]);

  function handleDelete(id) {
    dispatch(
      deleteProductCodeThunk({ id: id, page: page === 0 ? 1 : page + 1 })
    );
  }

  function handleEditIconClick(row) {
    if (
      !userPer.hasAccess(
        systemPermissions["vendorProfileDetailPage.product_code"],
        actions.edit
      )
    )
      return;
    setSelectedData(row);
    if (row.service_type === serviceType.exmyb) {
      setShowModal(true);
    } else setCustomService(true);
  }

  useEffect(() => {
    dispatch(fetchAllProductCodeThunk());
  }, [dispatch, businessId]);

  return (
    <>
      <div
        style={{ height: 400, width: "100%" }}
        key={new Date().toISOString()}
      >
        <Box
          sx={{
            display: "flex",
            marginBottom: "30px",
            justifyContent: "end",
          }}
        >
          {userPer.hasAccess(
            systemPermissions["vendorProfileDetailPage.product_code"],
            actions.create
          ) && (
            <Button
              variant="contained"
              onClick={handleOpen}
              startIcon={<Icon icon={plusFill} />}
            >
              Add New Product Code
            </Button>
          )}
        </Box>

        <Box
          sx={{
            height: "100%",
            width: 1,
            "& .table-header": {
              backgroundColor: "rgb(244, 246, 248)",
              color: "rgb(99, 115, 129)",
            },
          }}
        >
          <Card>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              sx={{
                display: { lg: "flex", md: "flex", sm: "block", xs: "block" },
              }}
            ></Stack>

            <Scrollbar>
              {productCodeList?.length > 0 ? (
                <TableContainer sx={{ minWidth: 800 }}>
                  <Table>
                    <ListHead
                      headLabel={TABLE_HEAD}
                      rowCount={productCodeList.length}
                      styles={{ backgroundColor: "rgb(243, 246, 248)" }}
                    />
                    <TableBody>
                      {productCodeList?.map((row) => {
                        return (
                          <TableRow
                            hover
                            key={row?.vendor_profile_id}
                            tabIndex={-1}
                            role="checkbox"
                            sx={{ cursor: "pointer" }}
                          >
                            <TableCell
                              onClick={() => handleEditIconClick(row)}
                              align="left"
                            >
                              {row?.service?.name}
                            </TableCell>
                            <TableCell
                              onClick={() => handleEditIconClick(row)}
                              align="left"
                            >
                              {row?.minimum_price || (
                                <em style={{ color: "gray" }}>N/A</em>
                              )}
                            </TableCell>
                            <TableCell
                              onClick={() => handleEditIconClick(row)}
                              align="left"
                            >
                              {row?.industry?.name}
                            </TableCell>
                            <TableCell
                              onClick={() => handleEditIconClick(row)}
                              align="left"
                            >
                              {row?.sub_industry?.name}
                            </TableCell>
                            <TableCell
                              onClick={() => handleEditIconClick(row)}
                              align="left"
                            >
                              {row?.delivery_class?.name}
                            </TableCell>
                            <TableCell
                              onClick={() => handleEditIconClick(row)}
                              align="left"
                            >
                              {row?.product_code}
                            </TableCell>
                            {/* <TableCell
                              onClick={() => handleEditIconClick(row)}
                              align="left"
                            >
                              <Chip
                                className={css`
                                  font-size: 12px;
                                  border: none;
                                  text-transform: capitalize;
                                  padding-left: 5px;
                                  padding-right: 5px;
                                  background: transparent;
                                `}
                                size="small"
                                label={
                                  row.verified ? (
                                    <CheckCircleOutlineIcon />
                                  ) : (
                                    <CancelOutlinedIcon />
                                  )
                                }
                                sx={{
                                  color: row.verified ? "#11a54a" : "#fe4842",
                                }}
                              />
                            </TableCell> */}
                            <TableCell align="left">
                              {userPer.hasAccess(
                                systemPermissions[
                                  "vendorProfileDetailPage.product_code"
                                ],
                                actions.delete
                              ) ? (
                                <DeleteAction
                                  handleDelete={() => handleDelete(row.id)}
                                  // disabled={
                                  //   !userPer.hasAccess(
                                  //     systemPermissions[
                                  //       "vendorProfileDetailPage.product_code"
                                  //     ],
                                  //     actions.delete
                                  //   )
                                  // }
                                />
                              ) : (
                                "-"
                              )}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                    <TableFooter>
                      <TableRow>
                        <TablePagination
                          count={totalServices}
                          rowsPerPage={rowsPerPage}
                          page={page}
                          onPageChange={handleChangePage}
                          rowsPerPageOptions={false}
                        />
                      </TableRow>
                    </TableFooter>
                  </Table>
                </TableContainer>
              ) : (
                <SearchNotFound
                  src="/static/support.svg"
                  subtitle="There is currently no services to show."
                />
              )}
            </Scrollbar>
          </Card>
        </Box>
      </div>

      <Modal
        isOpen={showModal}
        closeModal={handleClose}
        title="Add Service"
        content={
          <RoleModal
            data={selectedData}
            fncHandleClose={handleClose}
            page={page == 0 ? 1 : page + 1}
          />
        }
        dialogProps={{ fullWidth: true }}
        titleStyle={{ textAlign: "center", marginTop: 2 }}
        actionStyle={{ justifyContent: "center", marginBottom: 2 }}
      />

      <Modal
        isOpen={showCustomService}
        closeModal={() => {
          setCustomService(false);
          setSelectedData(null);
        }}
        title="Edit Service"
        content={
          <RoleModal
            data={selectedData}
            custom
            fncHandleClose={() => {
              setCustomService(false);
              setSelectedData(null);
            }}
            page={page == 0 ? 1 : page + 1}
          />
        }
        dialogProps={{ fullWidth: true }}
        titleStyle={{ textAlign: "center", marginTop: 2 }}
        actionStyle={{ justifyContent: "center", marginBottom: 2 }}
      />
    </>
  );
}

export default withBusinessId(ServiceOffered);
