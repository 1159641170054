import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";

export default function MuiModal({
  open,
  handleClose,
  handleSubmit,
  title,
  children,
  action,
  width = 500,
}) {
  //   const [open, setOpen] = React.useState(false);
  //   const handleOpen = () => setOpen(true);
  //   const handleClose = () => setOpen(false);
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    //   width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    margin: "1rem auto",
    width: width,
    maxHeight: "80vh",
    overflowY: "scroll",
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box position={"relative"}>
            <div
              style={{ position: "absolute", top: "-0.5rem", right: "-0.5rem" }}
            >
              <Button
                color="error"
                variant="outlined"
                onClick={handleClose}
                sx={{ minWidth: "0px" }}
              >
                x
              </Button>
            </div>
            <Typography
              id="spring-modal-title"
              variant="h6"
              component="h2"
              mb={4}
            >
              {title ? title : ""}
            </Typography>
            {/* <Typography id="spring-modal-description" sx={{ mt: 2 }}>
  Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
</Typography> */}
            {children}

            {action && (
              <Box sx={{ borderTop: "1px solid light gray" }} mt={2}>
                {action}
              </Box>
            )}
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
