import * as Yup from "yup";

export const addressInfovalidation = Yup.object({
  address_line1: Yup.string().required("Address is required"),
  // address_line2: Yup.string().required("Address2 is required"),
  city: Yup.string().required("City is required"),
  state: Yup.string().required("State is required"),
  country: Yup.string().required("Country is required"),
  zip_code: Yup.string()
    .required("Zip code is Required")
    .matches(/^[0-9]{6}$/, "Please Enter a Valid ZIP Code"),
  address_type: Yup.string().required("Address Type is Required"),
});

export const bankDetailsValidation = Yup.object({
  bank_name: Yup.string().required("Bank name is required"),
  account_number: Yup.string().required("Bank account number is required"),
  ifsc_code: Yup.string().required("Bank IFSC is required"),
  bank_branch: Yup.string().required("Bank branch name is required"),
  cancelled_cheque_file: Yup.object({
    url: Yup.string().required("Cancelled Cheque is required"),
  }),
});

export const reasonValidation = Yup.object({
  reason: Yup.array().min(1, "Reason is Required"),
});

export const customServicesValidation = Yup.object({
  service_name: Yup.string().required("Service name is required"),
  minimum_price: Yup.string().required("Minimum price is required"),
  maximum_price: Yup.string().required("Maximum price is required"),
});

export const serviceValidation = Yup.object({
  service_name: Yup.object({
    service_name: Yup.string().required("Service name is required"),
  }),
  minimum_price: Yup.string().required("Minimum price is required"),
  maximum_price: Yup.string().required("Maximum price is required"),
});

export const passwordvalidation = Yup.object().shape({
  old_password: Yup.string().required("Old password is required"),
  new_password: Yup.string().required("New password is required"),
  confirmpass: Yup.string()
    .required("Confirm password is required")
    .when("new_password", {
      is: (val) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf(
        [Yup.ref("new_password")],
        "Both password need to be the same"
      ),
    }),
});

export const portfolioValidation = Yup.object({
  client_name: Yup.string().required("Client Name is required"),
  // // website_link: Yup.string().url("Please Enter a Valid url"),
  // // app_link: Yup.string().url("Please Enter a Valid url"),
  // // client_budget: Yup.string().required("Project budget is required"),
  // // description: Yup.string().required("Description is required"),
  // service: Yup.object({
  //   service_name: Yup.string().required("Service name is Required"),
  // }),
  // product_code: Yup.object().shape({
  //   // id: Yup.string().required("Product code is Required"),
  //   // code: Yup.string().required("Product Code is Required"),
  // }),
  product_code: Yup.string().required("Product Code is Required"),
  // links_details: Yup.array().of(
  //   Yup.object().shape({
  //     frontend: Yup.string().required("Frontend is required"),
  //     backend: Yup.string().required("Backend is required"),
  //     cloud: Yup.string().required("Cloud is required"),
  //     database: Yup.string().required("Database  is required"),
  //   })
  // ),
});

export const verificationValidation = Yup.object({
  pan_number: Yup.string().required("Pan is required"),
  pan_file: Yup.object({
    url: Yup.string().required("Pan file is required"),
  }),
  nda_file: Yup.object({
    url: Yup.string().required("NDA file is required"),
  }),
  gst_number: Yup.string().required("GST is required"),
  gst_file: Yup.object({
    url: Yup.string().required("GST file is required"),
  }),
  aadhaar_number: Yup.string().required("Aadhar is required"),
  aadhaar_file: Yup.object({
    url: Yup.string().required("Aadhar file is required"),
  }),
  udyog_number: Yup.string().required("Udyog Number is required"),
  // msme_reg_number: Yup.string().required("MSME is required"),
  // msme_reg_file: Yup.object({
  //   url: Yup.string().required("MSME file is required"),
  // }),
});
export const verificationValidationWithMSME = Yup.object({
  pan_number: Yup.string().required("Pan is required"),
  pan_file: Yup.object({
    url: Yup.string().required("Pan file is required"),
  }),
  nda_file: Yup.object({
    url: Yup.string().required("NDA file is required"),
  }),
  gst_number: Yup.string().required("GST is required"),
  gst_file: Yup.object({
    url: Yup.string().required("GST file is required"),
  }),
  aadhaar_number: Yup.string().required("Aadhar is required"),
  aadhaar_file: Yup.object({
    url: Yup.string().required("Aadhar file is required"),
  }),
  msme_type: Yup.string().required("MSME type is required"),
  msme_reg_number: Yup.string().required("MSME is required"),
  msme_reg_file: Yup.object({
    url: Yup.string().required("MSME file is required"),
  }),
});

export const basicInfoValidations = Yup.object({
  brand_name: Yup.string().required("Business Entity Name is required"),
  // registered_business_name: Yup.string().required("Brand Name is required"),
  company_type: Yup.string().required("Company Type is required"),
  mobile: Yup.object({
    mobile_number: Yup.string()
      .required("Mobile is required")
      .max(10, "Please Enter a Valid Mobile Number")
      .min(10, "Please Enter a Valid Mobile Number")
      .test(
        "no-zero-only",
        "Mobile number cannot contain only 0",
        (value) => !/^[0]*$/.test(value)
      ),
  }),
  website: Yup.string()
    .required("Please enter website")
    .url("Please enter a url in https://www.domain.com format"),
  activeProjectNumber: Yup.string().required(
    "Please Enter Active Project Number"
  ),
  cmmiLevelSelect: Yup.string().required("Please Enter CMMI Level"),
  // year_of_establishment: Yup.string().required(
  //   "Year of Establishment is required"
  // ),
  // turnover: Yup.string().required("TurnOver is required"),
  // number_of_employees: Yup.string().required("Number of Employees is required"),
  // number_of_clients: Yup.string().required("Number of Clients is required"),

  // logo: Yup.object({
  //   url: Yup.string().required("Logo is required"),
  // }),
  // rating: Yup.string().required("Rating is required"),
  // description: Yup.string().required("Description is required"),
});

export const languageValidations = Yup.object({
  language: Yup.string().required("Language is required"),
});

export const skillsValidations = Yup.object({
  skills: Yup.string().required("Skills is required"),
});

export const techStackValidations = Yup.object({
  tech: Yup.string().required("Tech Stack is required"),
});

export const tagValidations = Yup.object({
  tag: Yup.string().required("Tag is required"),
});
export const categoryValidations = Yup.object({
  name: Yup.string().required("Category is required"),
});
export const notesValidations = Yup.object({
  note: Yup.string().required("Notes is required"),
});

// export const expertiseValidator = Yup.object({
//   expertise_breakup: Yup.array().of(
//     Yup.object({
//       stack_tash: Yup.string().required("Stack tash is equired"),
//       noe_1_3: Yup.number()
//         .typeError("you must specify a number")
//         .required("NOE is required"),
//       noe_4_8: Yup.number()
//         .typeError("you must specify a number")
//         .required("NOE is required"),
//       noe_8_above: Yup.number()
//         .typeError("you must specify a number")
//         .required("NOE is required"),
//     })
//   ),
// });
export const escalationLevelValidations = Yup.object({
  name: Yup.string().required("Name is required"),
  // last_name: Yup.string().required("Last Name is required"),
  email: Yup.string()
    .email("Invalid email format")
    .required("Email Id is required"),
  designation: Yup.string().required("Designation is required"),
  // age_range: Yup.string().required("Age Range  is required"),
  mobile: Yup.object({
    mobile_number: Yup.string().required("Mobile Number is Required"),
  }),
  // escalation_level: Yup.string().required("Escalation Level is required"),
});

export const expertiseValidations = Yup.object({
  stack_tash: Yup.string().required("Stack Tash is required"),
  NOE_1_3: Yup.number()
    .typeError("Must be a number")
    .required("Number of Employees is required"),
  NOE_4_8: Yup.number()
    .typeError("Must be a number")
    .required("Number of Employees is required"),
  NOE_8_plus: Yup.number()
    .typeError("Must be a number")
    .required("Number of Employees  is required"),
  resource_option: Yup.string().required("Resource Bifurcation is Required"),
});

export const teaminfoValidations = Yup.object({
  role: Yup.string().required("Role is required"),
  nop: Yup.number()
    .required("Number of people is required")
    .typeError("you must specify a number"),
});

export const turnoverValidations = Yup.object({
  year: Yup.string().required("Year is required"),
  projectBooked: Yup.string().required("Project Booked is Required"),
  projectBookedAmount: Yup.string().required(
    "Project Booked Amount is Required"
  ),
  projectDelivered: Yup.string().required("Project Delivered is Required"),
  projectDeliveredAmount: Yup.string().required(
    "Project Delivered Amount is Required"
  ),
});

export const createNewAccountValidation = Yup.object({
  first_name: Yup.string().required("First Name is Required"),
  last_name: Yup.string().required("Last Name is Required"),
  email: Yup.string()
    .required("Email is Required")
    .email("Please Enter a Valid email"),
  mobile: Yup.object({
    mobile_number: Yup.string()
      .required("Mobile is Required")
      .max(10, "Please Enter a Valid Mobile Number")
      .min(10, "Please Enter a Valid Mobile Number"),
  }),
});

//sass channel partner validation
export const sassChannelDetailsInfoValidation = Yup.object({
  sass_name: Yup.string()
    .required("Name is Required")
    .min(3, "Please Enter Minimum 3 Charcter")
    .test(
      "no-spaces",
      "Name cannot be only spaces",
      (value) => !/^\s+$/.test(value)
    ),
  sass_typeSelect: Yup.string().required("Please Atleast Select one"),
  sass_date_validTo: Yup.string().required("Date is Required"),
  mobile: Yup.object({
    mobile_number: Yup.string()
      .required("Mobile is Required")
      .max(10, "Please Enter a Valid Mobile Number")
      .min(10, "Please Enter a Valid Mobile Number")
      .test(
        "no-zero-only",
        "Mobile number cannot contain only 0",
        (value) => !/^[0]*$/.test(value)
      ),
  }),
});

export const founderProfileInfoValidation = Yup.object({
  name: Yup.string()
    .required("Name is Required")
    .min(3, "Please Enter Minimum 3 Charcter"),
  type: Yup.string().required("Please Atleast Select one"),
  email: Yup.string()
    .required("Email is  Required")
    .email("Enter the valid email"),
});

export const certificationValidation = Yup.object({
  type: Yup.string().required("Certification Type is Required"),
  valid_to: Yup.string().required("Please Select Date"),
  file: Yup.mixed().required("File is Required"),
  number: Yup.object({
    mobile_number: Yup.string()
      .required("Mobile is Required")
      .max(10, "Please Enter a Valid Mobile Number")
      .min(10, "Please Enter a Valid Mobile Number"),
  }),
  name: Yup.string().required("Name is Required"),
});
export const testimonialsValidation = Yup.object({
  spoc_name: Yup.string()
    .required("SPOC Name is Required")
    .min(3, "Please Enter Minimum 3 Character")
    .test(
      "no-spaces",
      "SPOC Name cannot be only spaces",
      (value) => !/^\s+$/.test(value)
    ),
  email: Yup.string()
    .required("Email is Required")
    .email("Enter the valid email"),
  company_name: Yup.string()
    .required("Company Name is Required")
    .test(
      "no-spaces",
      "Comapany Name cannot be only spaces",
      (value) => !/^\s+$/.test(value)
    ),
  contact: Yup.object({
    mobile_number: Yup.string()
      .required("Mobile is Required")
      .max(10, "Please Enter a Valid Mobile Number")
      .min(10, "Please Enter a Valid Mobile Number")
      .test(
        "no-zero-only",
        "Mobile number cannot contain only 0",
        (value) => !/^[0]*$/.test(value)
      ),
  }),
  service_delivered: Yup.string()
    .required("Service Delivered is Required")
    .test(
      "no-spaces",
      "Service Delivered cannot be only spaces",
      (value) => !/^\s+$/.test(value)
    ),
  product_code: Yup.string().required("Product Code is Required"),
});

export const platformReviewValidation = Yup.object({
  rating: Yup.string().required("Rating is required"),
  link: Yup.string()
    .matches(
      /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
      "Enter correct url!"
    )
    .required("Please enter website"),
});

export const BenchStrengthValidation = Yup.object({
  candidate_name: Yup.string().trim().required("Candidate name is required"),
  experience: Yup.string().required("Experience is Required"),
  resource_value: Yup.string().required("Resource bifuercation  is Required"),
  tech_stack: Yup.string().required("Tech Stack is Required"),
  team_model: Yup.string().required("Team Model is Required"),
  cost_per_month: Yup.string().required("Cost Per Month  is Required"),
  // cv: Yup.object({
  //   url: Yup.string().required("CV file is required"),
  // })
  //   .nullable()
  //   .default(null)
  //   .test("cv-file-required", "CV file is required", function (value) {
  //     // Check if `cv` is defined and `url` is empty
  //     return !value || (value && value.url); // Pass validation if `cv` is `null` or `url` has a value
  //   }),
  cv: Yup.mixed().required("CV file is required"),
});
export const BenchStrengthWithLocationValidation = Yup.object({
  candidate_name: Yup.string().trim().required("Candidate name is required"),
  experience: Yup.string().required("Experience is Required"),
  resource_value: Yup.string().required("Resource bifuercation  is Required"),
  tech_stack: Yup.string().required("Tech Stack is Required"),
  team_model: Yup.string().required("Team Model is Required"),
  cost_per_month: Yup.string().required("Cost Per Month  is Required"),
  location: Yup.array()
    // .of(Yup.string().required("Each location must be a valid string"))
    .min(1, "Location is required"),
  // cv: Yup.string().required("CV file is required"),
  cv: Yup.mixed()
    // .test(
    //   "is-string-or-object",
    //   "CV must be a string or an object with filename and url",
    //   (value) => {
    //     if (typeof value === "string") {
    //       return !!value.trim();
    //     }
    //     if (typeof value === "object" && value !== null) {
    //       return (
    //         typeof value.filename === "string" &&
    //         !!value.filename.trim() &&
    //         typeof value.url === "string" &&
    //         !!value.url.trim()
    //       );
    //     }
    //     return false;
    //   }
    // )
    .required("CV file is required"),
});

//social media tabs  valiadtion
export const socialMediaValidation = Yup.object().shape({
  // Define validation rules for each field
  socialMedia: Yup.array().of(
    Yup.object().shape({
      rating: Yup.string().required("Rating is required"),
      link: Yup.string().required("Link is required"),
    })
  ),
});

//past work tabs validation
export const pastWorkValidation = Yup.object().shape({
  service: Yup.string().required("Service Field  is Required"),
  industry: Yup.string().required("Industry Field  is Required"),
  sub_industry: Yup.string().required("Sub Industry Field   is Required"),
  delivery_class: Yup.string().required("Delivery Class  is Required"),
  //   service:Yup.array().of(
  //     Yup.object().shape({
  //         name: Yup.string().required("Service Field  is Required"),
  //         id: Yup.string().required("Service Field  is Required"),
  //     })
  // )
  //   // service: Yup.object({
  //   //   name: Yup.string().required("Service Field  is Required")
  //   // }),
});
