import {
  Box,
  Button,
  Card,
  Table,
  TableContainer,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
  TableFooter,
  TablePagination,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteTestimonialInfoThunk,
  fetchTestimonialInfoThunk,
} from "../store/slices/vendor/vendor.slice";
import plusFill from "@iconify/icons-eva/plus-fill";
import { Icon } from "@iconify/react";
import Scrollbar from "../components/Common/Scrollbar";
import SearchNotFound from "../components/NotFound/SearchNotFound";
import ListHead from "../components/Common/ListHead";
import TestimonialsModel from "../components/Modal/TestimonialsModel";
import Modal from "../components/Modal/Modal";
import EditIcon from "@mui/icons-material/Edit";
import DeleteAction from "../components/Common/DeleteAction";
import { getAllProductListData } from "../utils/server.util";
import usePermissions from "../hooks/usePermission";
import systemPermissions, { actions } from "../utils/global/permissions.global";

const TABLE_HEAD = [
  // { id: "id", label: "Id", alignRight: false },
  { id: "spoc_name", label: "SPOC Name", alignRight: false },
  { id: "email", label: "Email", alignRight: false },
  { id: "contact", label: "Contact", alignRight: false },
  { id: "company", label: "Company Name", alignRight: false },
  { id: "service_delivered", label: "Service Delivered", alignRight: false },
  { id: "product_code", label: "Product Code", alignRight: false },
  { id: "action", label: "Action", alignRight: false },
];

const Testimonials = () => {
  // dispatch the function
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchTestimonialInfoThunk());
  }, [dispatch]);
  const { testimonialInfoData, totalNumberTestimonial } = useSelector(
    ({ vendor }) => vendor
  );

  const [open, setOpen] = useState(false);
  const [singleTestimonialInfo, setSingleTestimonialInfo] = useState(null);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage] = React.useState(10);
  const [productList, setProductList] = useState([]);

  const userPerm = usePermissions();

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setSingleTestimonialInfo(null);
  };

  const handleEdit = (row) => {
    if (
      !userPerm.hasAccess(
        systemPermissions["vendorProfileDetailPage.testimonials"],
        actions.edit
      )
    )
      return;

    setSingleTestimonialInfo(row);
    setOpen(true);
  };

  useEffect(() => {
    (async function () {
      const response = await getAllProductListData();
      setProductList(
        response.map((ele) => ({
          value: ele.id,
          label: ele.code,
        }))
      );
    })();
  }, []);

  //delete and edit function write here
  const handleDelete = (id) => {
    dispatch(deleteTestimonialInfoThunk({ id, page: 1 }));
    setPage(0);
  };

  const handleChangePage = (_, newPage) => {
    dispatch(fetchTestimonialInfoThunk(newPage + 1));
    setPage(newPage);
  };

  return (
    <>
      <div style={{ height: 400, width: "100%" }}>
        <Box
          sx={{
            display: "flex",
            marginBottom: "30px",
            justifyContent: "end",
          }}
        >
          {userPerm.hasAccess(
            systemPermissions["vendorProfileDetailPage.testimonials"],
            actions.create
          ) && (
            <Button
              onClick={handleOpen}
              variant="contained"
              sx={{ float: "right" }}
              startIcon={<Icon icon={plusFill} />}
            >
              Add Testimonials
            </Button>
          )}
        </Box>

        <Box
          sx={{
            height: "100%",
            width: 1,
            "& .table-header": {
              backgroundColor: "rgb(244, 246, 248)",
              color: "rgb(99, 115, 129)",
            },
          }}
        >
          <Card>
            <Scrollbar>
              {testimonialInfoData.length > 0 ? (
                <TableContainer sx={{ minWidth: 800 }}>
                  <Table>
                    <ListHead
                      headLabel={TABLE_HEAD}
                      rowCount={testimonialInfoData?.length}
                      styles={{ backgroundColor: "rgb(243, 246, 248)" }}
                    />
                    <TableBody>
                      {testimonialInfoData?.map((row) => {
                        const {
                          id,
                          spoc_name,
                          email,
                          phone_number,
                          company_name,
                          service_delivered,
                          product_code,
                        } = row;
                        return (
                          <TableRow
                            hover
                            key={id}
                            tabIndex={-1}
                            role="checkbox"
                            sx={{ cursor: "pointer" }}
                          >
                            {/* <TableCell align="left">{id}</TableCell> */}
                            <TableCell align="left">{spoc_name}</TableCell>
                            <TableCell align="left">{email}</TableCell>
                            <TableCell align="left">{phone_number}</TableCell>
                            <TableCell align="left">{company_name}</TableCell>
                            <TableCell align="left">
                              {service_delivered}
                            </TableCell>
                            <TableCell align="left">
                              {product_code?.code}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {userPerm.hasAccess(
                                systemPermissions[
                                  "vendorProfileDetailPage.testimonials"
                                ],
                                actions.edit
                              ) ? (
                                <IconButton color="success" component="span">
                                  <EditIcon
                                    color="success"
                                    sx={{ cursor: "pointer" }}
                                    onClick={() => handleEdit(row)}
                                  />
                                </IconButton>
                              ) : (
                                "-"
                              )}
                              {userPerm.hasAccess(
                                systemPermissions[
                                  "vendorProfileDetailPage.testimonials"
                                ],
                                actions.delete
                              ) ? (
                                <IconButton color="error" component="span">
                                  <DeleteAction
                                    handleDelete={() => handleDelete(row.id)}
                                  />
                                </IconButton>
                              ) : (
                                "-"
                              )}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                    <TableFooter>
                      <TableRow>
                        <TablePagination
                          count={totalNumberTestimonial}
                          rowsPerPage={rowsPerPage}
                          page={page}
                          onPageChange={handleChangePage}
                          rowsPerPageOptions={false}
                        />
                      </TableRow>
                    </TableFooter>
                  </Table>
                </TableContainer>
              ) : (
                <SearchNotFound
                  src="/static/support.svg"
                  subtitle="There is currently no testimonialInfoData to show."
                />
              )}
            </Scrollbar>
          </Card>
        </Box>
      </div>

      <Modal
        isOpen={open}
        closeModal={handleClose}
        title="Testimonial Info"
        content={
          <TestimonialsModel
            singleTestimonialInfo={singleTestimonialInfo}
            handleClose={handleClose}
            productList={productList}
          />
        }
        dialogProps={{ fullWidth: true }}
        titleStyle={{ textAlign: "center", marginTop: 2 }}
        actionStyle={{ justifyContent: "center", marginBottom: 2 }}
      />
    </>
  );
};

export default Testimonials;
