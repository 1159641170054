import { pathToRegexp } from "path-to-regexp";
import {
  addressesPath,
  bankDetailsPath,
  basicInfoPath,
  benchStrength,
  certification,
  expertiseBreakup,
  founderInfo,
  poc,
  portfolioPath,
  saasChannelPartner,
  services,
  socialMediaRating,
  techStackForte,
  testimonials,
  uploadPortfolioPath,
  verificationPath,
} from "../constant";

const systemPermissions = {
  "dashboard.analytics": 1,
  vendorShorlistListPage: 2,
  "vendorProfileDetailPage.info": 3,
  "vendorProfileDetailPage.address": 4,
  "vendorProfileDetailPage.poc": 5,
  supportListPage: 6,
  "supportItem.mark_as_resolved": 7,
  "supportItem.messages": 8,
  financeApprovalListPage: 9,
  "financeApprovalItem.approve": 10,
  "financeApprovalItem.reject": 11,
  "vendorProfileDetailPage.foundersInfo": 12,
  "vendorProfileDetailPage.bankDetails": 13,
  "vendorProfileDetailPage.verification": 14,
  "vendorProfileDetailPage.services": 15,
  "vendorProfileDetailPage.pastWork": 16,
  "vendorProfileDetailPage.corporate_deck": 17,
  "vendorProfileDetailPage.testimonials": 18,
  "vendorProfileDetailPage.saasChannelPartner": 19,
  "vendorProfileDetailPage.certifications": 20,
  "vendorProfileDetailPage.expertiseBreakup": 21,
  "vendorProfileDetailPage.platformReview": 22,
  "vendorProfileDetailPage.techStackForte": 23,
  "vendorProfileDetailPage.benchStrength": 24,
  "contentRepo.services": 25,
  "contentRepo.industry": 26,
  "contentRepo.language": 27,
  "contentRepo.techStack": 28,
  "contentRepo.tags": 29,
  "contentRepo.category": 30,
  "VendorShorlistItem.shortlist": 31,
  "vendorProfileMainPage.allVendors": 32,
  "vendorProfileMainPage.approvedVendors": 33,
  "VendorPorfileMainPage.export": 34,
  "vendorProfileMainPage.filter": 35,
  "VendorShorlistItem.filter": 36,
  "financeApprovalListPage.view": 37,
  "vendorProfileDetailPage.canApprove": 38,
  "vendorProfileDetailPage.product_code": 39,
  raDetailPage: 40,
  raListPage: 41,
  // vetOverviewPage: 42,
};

export default systemPermissions;

export const actions = {
  read: "read",
  edit: "edit",
  delete: "delete",
  create: "create",
  export: "export",
};

const prefix = "/dashboard/profiles/:id/";
// const raPrefix = "/dashboard/ra/:id/";

export const listPagesUrlMapper = {
  "/dashboard/contact": "supportListPage",
  "/dashboard/profiles": [
    "vendorProfileMainPage.approvedVendors",
    "vendorProfileMainPage.allVendors",
  ],
  "/dashboard/shortlist": "vendorShorlistListPage",
  "/dashboard/financeApproval": "financeApprovalListPage",
  [prefix + basicInfoPath]: "vendorProfileDetailPage.info",
  [prefix + addressesPath]: "vendorProfileDetailPage.address",
  [prefix + poc]: "vendorProfileDetailPage.poc",
  [prefix + founderInfo]: "vendorProfileDetailPage.foundersInfo",
  [prefix + bankDetailsPath]: "vendorProfileDetailPage.bankDetails",
  [prefix + verificationPath]: "vendorProfileDetailPage.verification",
  [prefix + services]: "vendorProfileDetailPage.services",
  [prefix + portfolioPath]: "vendorProfileDetailPage.pastWork",
  [prefix + "service"]: "vendorProfileDetailPage.product_code",
  [prefix + uploadPortfolioPath]: "vendorProfileDetailPage.corporate_deck",
  [prefix + testimonials]: "vendorProfileDetailPage.testimonials",
  [prefix + saasChannelPartner]: "vendorProfileDetailPage.saasChannelPartner",
  [prefix + certification]: "vendorProfileDetailPage.certifications",
  [prefix + expertiseBreakup]: "vendorProfileDetailPage.expertiseBreakup",
  [prefix + socialMediaRating]: "vendorProfileDetailPage.platformReview",
  [prefix + techStackForte]: "vendorProfileDetailPage.techStackForte",
  [prefix + benchStrength]: "vendorProfileDetailPage.benchStrength",
  "/dashboard/shortlist/:id": "VendorShorlistItem.shortlist",
  "/dashboard/contact/tickets/:id": "supportListPage",
  "/dashboard/ra": "raListPage",
  "/dashboard/profiles/new/basic": "vendorProfileMainPage.allVendors",
  "/dashboard/services": "contentRepo.services",
  "/dashboard/industry": "contentRepo.industry",
  "/dashboard/language": "contentRepo.language",
  "/dashboard/tech-stack": "contentRepo.techStack",
  "/dashboard/tag": "contentRepo.tags",
  "/dashboard/category": "contentRepo.category",
};

export const whilteListUrls = [
  "^/dashboard/app$",
  "^/dashboard/myProfile$",
  "^/dashboard/vetting-repo",
  "^/dashboard/ra/.*$",
];

const createPattern = (pattern) => {
  return pattern.replace(/:id/g, "([0-9a-fA-F-]+)");
};

export const matchUrlToPattern = (url, pattern) => {
  const keys = [];
  const regex = pathToRegexp(createPattern(pattern), keys);
  const result = regex.exec(url);

  return result !== null;
};

export const getMappedPermission = (pathname) => {
  let url = "";
  Object.keys(listPagesUrlMapper).forEach((ele) => {
    if (matchUrlToPattern(pathname, ele)) {
      url = ele;
    }
  });

  return listPagesUrlMapper[url];
};

export const isWhitelisted = (pathname) => {
  return whilteListUrls.some((pattern) => new RegExp(pattern).test(pathname));
};
