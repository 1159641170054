import { Card, Typography } from "@mui/material";
import { Pagination, Table } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllShortlistedVendorsThunk } from "../../store/slices/ra/ra.slice";
import { useParams } from "react-router-dom";

const RaShortlistedVendors = () => {
  const { raEnableVendors, raShortlistedVendors } = useSelector(({ ra }) => ra);
  const dispatch = useDispatch();
  const params = useParams();
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalVendor, setTotalVendor] = useState(0);

  useEffect(() => {
    setTotalVendor(raShortlistedVendors.total || 0);
  }, [raShortlistedVendors.total]);

  useEffect(() => {
    dispatch(getAllShortlistedVendorsThunk(params.leadId));
  }, []);

  const columns = [
    {
      title: "Vendor Name",
      dataIndex: "registered_business_name",
    },
    {
      title: "Skill Operators",
      dataIndex: "skills_operates",
      render: (text) => <span>{text ?? " - "}</span>,
    },
    {
      title: "Leads Given",
      dataIndex: "requirement_given",
      render: (text) => <span>{text ?? " - "}</span>,
    },
    {
      title: "Resource Working",
      dataIndex: "resource_working",
      render: (text) => <span>{text ?? " - "}</span>,
    },
    {
      title: "Bench Strength",
      dataIndex: "bench_strength",
      render: (text) => <span>{text ?? " - "}</span>,
    },
    // {
    //   title: "Analytics",
    //   dataIndex: "analytics",
    //   render: (text) => <a onClick={handleOpen}>{text ?? "view"}</a>,
    // },
  ];

  //   console.log(raShortlistedVendors, "raShortlistedVendors");

  return (
    <>
      <Card
        style={{
          padding: "1rem",
          marginTop: "1rem",
          marginBottom: "1rem",
          width: "100%",
        }}
      >
        <div style={{ padding: "2rem 0" }}>
          <Typography variant="h4"> Shortlisted Vendors: </Typography>
        </div>
        <div style={{ width: "100%" }}>
          <Table
            rowSelection={false}
            columns={columns}
            dataSource={raShortlistedVendors
              ?.filter(
                (item, ind) =>
                  ind >= (page - 1) * rowsPerPage && ind < page * rowsPerPage
              )
              .map((ele) => ({
                ...ele,
                key: ele.id,
              }))}
            pagination={false}
          />
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              width: "100%",
              marginTop: "20px",
            }}
          >
            <Pagination
              current={page}
              total={totalVendor}
              showTotal={(total, range) =>
                `${range[0]}-${range[1]} of ${total} items`
              }
              defaultPageSize={rowsPerPage}
              showSizeChanger={false}
              //   onChange={handlePageChange}
            />
          </div>
        </div>
      </Card>
    </>
  );
};
export default RaShortlistedVendors;
