import { Box, Button, Card, Stack, Typography } from "@mui/material";
import { Pagination, Table } from "antd";
import ListSearchbar from "../../components/Common/ListSearchbar";
import { useMemo, useState, useEffect } from "react";
import useAdvanceFilter from "../../hooks/useAdvanceFilter";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Icon } from "@iconify/react";
import { getAllglobalRepoCvsThunk } from "../../store/slices/ra/ra.slice";

// Define your table columns
const columns = [
  {
    title: "Candidate Name",
    dataIndex: "applicant_name",
  },
  {
    title: "Vendor Name",
    dataIndex: "vendor_name",
    render: (text) => <span>{text ?? " - "}</span>,
  },
  {
    title: "Skill ",
    dataIndex: "skill_name",
    render: (text) => <span>{text ?? " - "}</span>,
  },
  {
    title: "Budget",
    dataIndex: "vendor_budget",
    render: (text) => <span>{text ?? " - "}</span>,
  },
  {
    title: "Candidate Cv",
    dataIndex: "cv_filename",
    render: (_, record) => {
      const { cv_filename, cv_url } = record; // assuming you now receive filename and url separately
      return cv_filename && cv_url ? (
        <a href={cv_url} target="_blank" rel="noopener noreferrer">
          {cv_filename}
        </a>
      ) : (
        <span> - </span>
      );
    },
  },
  {
    title: "Experience",
    dataIndex: "year_of_exp",
    render: (text) => <span>{text ?? " - "}</span>,
  },
  {
    title: "Cv Status",
    dataIndex: "emb_applicant_status",
    render: (status) => (
      <span className="p-1 rounded-lg px-3 font-semibold ">
        {status ? (
          <span className="text-green-500 " style={{ color: "green" }}>
            {status.split("_").join(" ")}
          </span>
        ) : (
          " - "
        )}
      </span>
    ),
  },
  // {
  //   title: "Action",
  //   dataIndex: "action",
  //   render: (_, record) => (
  //     <Button size="small" color="secondary">
  //       View
  //     </Button>
  //   ),
  // },
];

const dataSource = [
  {
    key: "1",
    applicant_name: "John Doe",
    skill: "JavaScript",
    vendor_budget: "$1000 - $1500",
    cv_filename: {
      filename: "john_doe_cv.pdf",
      url: "/uploads/cvs/john_doe_cv.pdf",
    },
    year_of_exp: "5 years",
    cv_status: "Approved",
  },
  {
    key: "2",
    applicant_name: "Jane Smith",
    skill: "React",
    vendor_budget: "$1500 - $2000",
    cv_filename: {
      filename: "jane_smith_cv.pdf",
      url: "/uploads/cvs/jane_smith_cv.pdf",
    },
    year_of_exp: "3 years",
    cv_status: "Pending",
  },
  {
    key: "3",
    applicant_name: "Sam Wilson",
    skill: "Node.js",
    vendor_budget: "$2000 - $2500",
    cv_filename: {
      filename: "sam_wilson_cv.pdf",
      url: "/uploads/cvs/sam_wilson_cv.pdf",
    },
    year_of_exp: "7 years",
    cv_status: "Approved",
  },
  {
    key: "4",
    applicant_name: "Emma Johnson",
    skill: "Angular",
    vendor_budget: "$1200 - $1800",
    cv_filename: {
      filename: "emma_johnson_cv.pdf",
      url: "/uploads/cvs/emma_johnson_cv.pdf",
    },
    year_of_exp: "4 years",
    cv_status: "Pending",
  },
  {
    key: "5",
    applicant_name: "Michael Brown",
    skill: "Python",
    vendor_budget: "$1800 - $2300",
    cv_filename: {
      filename: "michael_brown_cv.pdf",
      url: "/uploads/cvs/michael_brown_cv.pdf",
    },
    year_of_exp: "6 years",
    cv_status: "Approved",
  },
  {
    key: "6",
    applicant_name: "Olivia Davis",
    skill: "Ruby on Rails",
    vendor_budget: "$2200 - $2700",
    cv_filename: {
      filename: "olivia_davis_cv.pdf",
      url: "/uploads/cvs/olivia_davis_cv.pdf",
    },
    year_of_exp: "8 years",
    cv_status: "Approved",
  },
  {
    key: "7",
    applicant_name: "Liam Martinez",
    skill: "Vue.js",
    vendor_budget: "$1400 - $1900",
    cv_filename: {
      filename: "liam_martinez_cv.pdf",
      url: "/uploads/cvs/liam_martinez_cv.pdf",
    },
    year_of_exp: "3 years",
    cv_status: "Pending",
  },
  {
    key: "8",
    applicant_name: "Sophia Rodriguez",
    skill: "Java",
    vendor_budget: "$1600 - $2100",
    cv_filename: {
      filename: "sophia_rodriguez_cv.pdf",
      url: "/uploads/cvs/sophia_rodriguez_cv.pdf",
    },
    year_of_exp: "5 years",
    cv_status: "Approved",
  },
  {
    key: "9",
    applicant_name: "William Garcia",
    skill: "C#",
    vendor_budget: "$1700 - $2200",
    cv_filename: {
      filename: "william_garcia_cv.pdf",
      url: "/uploads/cvs/william_garcia_cv.pdf",
    },
    year_of_exp: "6 years",
    cv_status: "Pending",
  },
  {
    key: "10",
    applicant_name: "Isabella Miller",
    skill: "PHP",
    vendor_budget: "$1300 - $1800",
    cv_filename: {
      filename: "isabella_miller_cv.pdf",
      url: "/uploads/cvs/isabella_miller_cv.pdf",
    },
    year_of_exp: "4 years",
    cv_status: "Approved",
  },
  {
    key: "11",
    applicant_name: "James Anderson",
    skill: "Golang",
    vendor_budget: "$2400 - $2900",
    cv_filename: {
      filename: "james_anderson_cv.pdf",
      url: "/uploads/cvs/james_anderson_cv.pdf",
    },
    year_of_exp: "8 years",
    cv_status: "Approved",
  },
  {
    key: "12",
    applicant_name: "Mia Taylor",
    skill: "Swift",
    vendor_budget: "$2100 - $2600",
    cv_filename: {
      filename: "mia_taylor_cv.pdf",
      url: "/uploads/cvs/mia_taylor_cv.pdf",
    },
    year_of_exp: "7 years",
    cv_status: "Pending",
  },
  {
    key: "13",
    applicant_name: "Benjamin Thomas",
    skill: "Kotlin",
    vendor_budget: "$2000 - $2500",
    cv_filename: {
      filename: "benjamin_thomas_cv.pdf",
      url: "/uploads/cvs/benjamin_thomas_cv.pdf",
    },
    year_of_exp: "6 years",
    cv_status: "Approved",
  },
  {
    key: "14",
    applicant_name: "Charlotte Lee",
    skill: "Flutter",
    vendor_budget: "$1500 - $2000",
    cv_filename: {
      filename: "charlotte_lee_cv.pdf",
      url: "/uploads/cvs/charlotte_lee_cv.pdf",
    },
    year_of_exp: "5 years",
    cv_status: "Pending",
  },
  {
    key: "15",
    applicant_name: "Elijah Harris",
    skill: "TypeScript",
    vendor_budget: "$1700 - $2200",
    cv_filename: {
      filename: "elijah_harris_cv.pdf",
      url: "/uploads/cvs/elijah_harris_cv.pdf",
    },
    year_of_exp: "4 years",
    cv_status: "Approved",
  },
  {
    key: "16",
    applicant_name: "Amelia Walker",
    skill: "SQL",
    vendor_budget: "$1100 - $1600",
    cv_filename: {
      filename: "amelia_walker_cv.pdf",
      url: "/uploads/cvs/amelia_walker_cv.pdf",
    },
    year_of_exp: "3 years",
    cv_status: "Pending",
  },
  {
    key: "17",
    applicant_name: "Lucas Young",
    skill: "NoSQL",
    vendor_budget: "$1800 - $2300",
    cv_filename: {
      filename: "lucas_young_cv.pdf",
      url: "/uploads/cvs/lucas_young_cv.pdf",
    },
    year_of_exp: "5 years",
    cv_status: "Approved",
  },
  {
    key: "18",
    applicant_name: "Harper King",
    skill: "AWS",
    vendor_budget: "$2000 - $2500",
    cv_filename: {
      filename: "harper_king_cv.pdf",
      url: "/uploads/cvs/harper_king_cv.pdf",
    },
    year_of_exp: "6 years",
    cv_status: "Approved",
  },
  {
    key: "19",
    applicant_name: "Ethan Wright",
    skill: "Azure",
    vendor_budget: "$2100 - $2600",
    cv_filename: {
      filename: "ethan_wright_cv.pdf",
      url: "/uploads/cvs/ethan_wright_cv.pdf",
    },
    year_of_exp: "7 years",
    cv_status: "Pending",
  },
  {
    key: "20",
    applicant_name: "Abigail Scott",
    skill: "GCP",
    vendor_budget: "$2200 - $2700",
    cv_filename: {
      filename: "abigail_scott_cv.pdf",
      url: "/uploads/cvs/abigail_scott_cv.pdf",
    },
    year_of_exp: "8 years",
    cv_status: "Approved",
  },
  {
    key: "21",
    applicant_name: "Alexander Evans",
    skill: "Docker",
    vendor_budget: "$1700 - $2200",
    cv_filename: {
      filename: "alexander_evans_cv.pdf",
      url: "/uploads/cvs/alexander_evans_cv.pdf",
    },
    year_of_exp: "4 years",
    cv_status: "Approved",
  },
  {
    key: "22",
    applicant_name: "Ava Mitchell",
    skill: "Kubernetes",
    vendor_budget: "$1800 - $2300",
    cv_filename: {
      filename: "ava_mitchell_cv.pdf",
      url: "/uploads/cvs/ava_mitchell_cv.pdf",
    },
    year_of_exp: "5 years",
    cv_status: "Pending",
  },
  {
    key: "23",
    applicant_name: "Henry Carter",
    skill: "Terraform",
    vendor_budget: "$2000 - $2500",
    cv_filename: {
      filename: "henry_carter_cv.pdf",
      url: "/uploads/cvs/henry_carter_cv.pdf",
    },
    year_of_exp: "6 years",
    cv_status: "Approved",
  },
  {
    key: "24",
    applicant_name: "Evelyn Phillips",
    skill: "Jenkins",
    vendor_budget: "$1300 - $1800",
    cv_filename: {
      filename: "evelyn_phillips_cv.pdf",
      url: "/uploads/cvs/evelyn_phillips_cv.pdf",
    },
    year_of_exp: "4 years",
    cv_status: "Pending",
  },
  {
    key: "25",
    applicant_name: "Daniel Perez",
    skill: "Ansible",
    vendor_budget: "$1900 - $2400",
    cv_filename: {
      filename: "daniel_perez_cv.pdf",
      url: "/uploads/cvs/daniel_perez_cv.pdf",
    },
    year_of_exp: "7 years",
    cv_status: "Approved",
  },
];

const GlobalRepo = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalVendor, setTotalVendor] = useState(0); // Total items
  const [data, setData] = useState([]); // Data for current page
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });

  const filterState = useAdvanceFilter({ search });
  const { global_cvs } = useSelector(({ ra }) => ra);

  useEffect(() => {
    dispatch(getAllglobalRepoCvsThunk());
  }, []);

  // console.log(global_cvs, "global_cvs");

  useEffect(() => {
    // Fetch data when the component mounts or when pagination changes
    dispatch(
      getAllglobalRepoCvsThunk({
        page: pagination.current,
        pageSize: pagination.pageSize,
      })
    )
      .unwrap()
      .then((response) => {
        setData(response.data);
        setPagination((prev) => ({
          ...prev,
          total: response.total,
        }));
      });
  }, [dispatch, pagination.current, pagination.pageSize]);

  const handleTableChange = (pagination) => {
    setPagination({
      ...pagination,
      current: pagination.current,
    });
  };

  const handleAction = (id) => {
    // Handle the action button click, maybe navigate to a detail page or open a modal
    console.log("View details for ID:", id);
  };

  const [showDrawer, setShowDrawer] = useState(false);

  const fetchVendors = (searchValue = "", pageValue = 1) => {
    // Example fetch function, you should replace it with your actual API call
    const filteredData = global_cvs.filter((item) =>
      item.applicant_name.toLowerCase().includes(searchValue.toLowerCase())
    );

    const paginatedData = filteredData.slice(
      (pageValue - 1) * rowsPerPage,
      pageValue * rowsPerPage
    );

    setData(paginatedData);
    setTotalVendor(filteredData.length);
  };

  useEffect(() => {
    fetchVendors(search, page);
  }, [search, page]);

  const _searchChange = useMemo(() => {
    return _.debounce((value) => {
      if (!value) {
        setPage(1);
        fetchVendors();
      } else {
        setPage(1);
        fetchVendors(value);
      }
    }, 500);
  }, []);

  function handleSearchChange(e) {
    const value = e.target.value;
    setSearch(value);
    _searchChange(value);
  }

  const handlePageChange = (pageValue) => {
    setPage(pageValue);
  };

  return (
    <>
      <Card sx={{ padding: "1rem" }}>
        <Box>
          {/* <Typography variant="h5" pb={3}>
            All Global Cvs:
          </Typography> */}
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{
              display: { lg: "flex", md: "flex", sm: "block", xs: "block" },
            }}
          >
            {/* <ListSearchbar
              placeholder="Search global repo profile"
              handleSearchChange={handleSearchChange}
              styles={{
                width: "280px",
              }}
            /> */}
            {/* <Button
              variant="outlined"
              sx={{ padding: "10px 20px", marginRight: "20px" }}
              onClick={() => setShowDrawer(true)}
            >
              <Icon icon="bytesize:filter" />
              <span style={{ marginLeft: "10px" }}>Filter</span>
            </Button> */}
          </Stack>

          <div style={{ width: "100%" }}>
            <Table
              rowSelection={false}
              columns={columns}
              rowKey="id"
              dataSource={global_cvs.map((ele, index) => ({
                ...ele,
                key: (page - 1) * rowsPerPage + index + 1,
              }))}
              pagination={false}
            />
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                width: "100%",
                marginTop: "20px",
              }}
            >
              {/* <Pagination
                current={page}
                total={totalVendor}
                showTotal={(total, range) =>
                  `${range[0]}-${range[1]} of ${total} items`
                }
                defaultPageSize={rowsPerPage}
                showSizeChanger={false}
                onChange={handlePageChange}
              /> */}
            </div>
          </div>
        </Box>
      </Card>
    </>
  );
};

export default GlobalRepo;
