import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchRaLeadLineItemsThunk,
  postShorlistedVendorsThunk,
  putEmbJdUploadThunk,
  putMoveDealThunk,
} from "../../store/slices/ra/ra.slice";
import { useParams, Outlet, useNavigate } from "react-router-dom";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Box,
  Stack,
  Avatar,
  Paper,
  Button,
  TextField,
  IconButton,
  Input,
} from "@mui/material";
import clsx from "clsx";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import GroupsIcon from "@mui/icons-material/Groups";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import DescriptionIcon from "@mui/icons-material/Description";
import PhoneIcon from "@mui/icons-material/Phone";
import PersonIcon from "@mui/icons-material/Person";
import { DateTime } from "luxon";

import { capitalCase } from "../../utils/change-case";
import { useStyles } from "./RaDetails.theme";
import Element from "../../components/Form/Element";
import { inputType } from "../../utils/enum";
import { useFormik } from "formik";
import { Modal } from "antd";
import { RALeadLineItemStatus } from "../../utils/enum";

const lineItemStatus = [
  { value: "qualification", label: "Qualification" },
  // { value: "vendor-shortlist", label: "Vendor Shortlist" },
  // { value: "cv-vetting", label: "CV Vetting" },
  // { value: "move-to-client", label: "Move To Client" },
  // { value: "client-round", label: "Client Round" },
  // { value: "closed-won", label: "Closed Won" },
];

function RaDetails() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const lead = useSelector(({ ra }) => ra.lead);

  const [cdModal, setCdModal] = useState(false);

  const [mdModal, setMdModal] = useState(false);

  useEffect(() => {
    dispatch(fetchRaLeadLineItemsThunk(params.leadId))
      .unwrap()
      .catch(() => {
        navigate("/dashboard/ra");
      });
  }, []);

  function openCDModal() {
    setCdModal(true);
  }
  function openMDModal() {
    setMdModal(true);
  }
  function closeCDModal() {
    setCdModal(false);
    closeDealFormik.resetForm();
  }
  function closeMDModal() {
    setMdModal(false);
    moveDealFormik.resetForm();
  }

  const formik = useFormik({
    initialValues: {
      file: null,
      description: "",
    },
    // validationSchema: raDocumentUpdateValidation,
    onSubmit: (values) => {
      const payload = {
        emb_jd_file_url: values.file.url,
        emb_jd_filename: values.file.filename,
        emb_job_description: values.description,
        // emb_budget: null,
      };

      // Dispatch your action with the payload here
      dispatch(putEmbJdUploadThunk({ data: payload, id: params.leadId }))
        .unwrap()
        .then(dispatch(fetchRaLeadLineItemsThunk(params.leadId)));

      // console.log("payload", payload);
    },
  });

  useEffect(() => {
    if (lead) {
      formik.setValues({
        file: {
          filename: lead.emb_jd_filename,
          url: lead.emb_jd_file_url,
        },
        description: lead?.emb_job_description,
      });
    }
  }, [lead]);

  const closeDealFormik = useFormik({
    initialValues: {
      remark: "",
    },
    onSubmit: (values) => {
      // console.log("formik value :", values);
      const payload = {
        status: RALeadLineItemStatus.CLOSED_LOST,
        remark: values.remark,
      };
      dispatch(putMoveDealThunk({ data: payload, id: params.leadId }))
        .unwrap()
        .then(() => {
          closeCDModal();
          dispatch(fetchRaLeadLineItemsThunk(params.leadId));
        });
    },
  });

  const moveDealFormik = useFormik({
    initialValues: {
      status: "",
      remark: "",
    },
    onSubmit: (values) => {
      // console.log(values);
      dispatch(putMoveDealThunk({ data: values, id: params.leadId }))
        .unwrap()
        .then(() => {
          closeMDModal();
          dispatch(fetchRaLeadLineItemsThunk(params.leadId));
        });
    },
  });

  const handleCDSubmit = () => {
    closeDealFormik.handleSubmit();
  };
  const handleMDSubmit = () => {
    moveDealFormik.handleSubmit();
  };
  function handleJdSubmit() {
    formik.handleSubmit();
  }

  // console.log("lead", lead);

  return (
    <>
      <Grid item xs={12} sm={12} md={12}>
        <Card mb={5}>
          <CardContent>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              mb={2}
            >
              <Box sx={{ display: "flex", alignItems: "center", gap: "1rem" }}>
                <Typography
                  gutterBottom
                  variant="h3"
                  sx={{
                    marginBottom: 0,
                    fontSize: { xs: "1rem", sm: "1.5rem" },
                  }}
                >
                  RA Leads Details
                </Typography>
                <Box
                  // component="span"
                  // display="inline"
                  className={clsx(classes.badge_blue, classes.badge)}
                  sx={{ fontSize: { xs: "16px", sm: "16px" } }}
                >
                  {capitalCase(lead?.lead_line_item_status)}
                  {/* {lead?.lead_line_item_status} */}
                </Box>
              </Box>
              <Box
                // width={200}
                sx={{ display: "flex", alignItems: "center", gap: "1rem" }}
                alignItems="center"
                justifyContent="center"
                gap={2}
              >
                {lead?.lead_line_item_status === "vendor-shortlist" && (
                  <Box>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={openMDModal}
                    >
                      Move Deal
                    </Button>
                  </Box>
                )}
                {(lead?.lead_line_item_status === "new" ||
                  lead?.lead_line_item_status === "qualification") && (
                  <Box>
                    <Button
                      variant="contained"
                      color="error"
                      onClick={openCDModal}
                    >
                      Close Deal
                    </Button>
                  </Box>
                )}
              </Box>
            </Stack>
            <Box mb={5}>
              <Stack direction="row" spacing={4} justifyContent="space-start">
                <Typography
                  variant="body1"
                  sx={{
                    fontSize: { xs: "15px" },
                    color: "#4d4d4d",
                    fontWeight: "600",
                    display: "flex",
                    alignItems: "center",
                    gap: 1,
                  }}
                >
                  <PersonIcon sx={{ fontSize: "15px" }} /> Name :{" "}
                  {capitalCase(lead?.user?.name)}
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    fontSize: { xs: "15px" },
                    color: "#4d4d4d",
                    fontWeight: "600",
                    display: "flex",
                    alignItems: "center",
                    gap: 1,
                  }}
                >
                  <AlternateEmailIcon sx={{ fontSize: "15px" }} /> Email :{" "}
                  {lead?.user?.email}
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    fontSize: { xs: "15px" },
                    color: "#4d4d4d",
                    fontWeight: "600",
                    display: "flex",
                    alignItems: "center",
                    gap: 1,
                  }}
                >
                  <LocationCityIcon sx={{ fontSize: "15px" }} />
                  Company Name : {capitalCase(lead?.user?.company_name)}
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    fontSize: { xs: "15px" },
                    color: "#4d4d4d",
                    fontWeight: "600",
                    display: "flex",
                    alignItems: "center",
                    gap: 1,
                  }}
                >
                  <PhoneIcon sx={{ fontSize: "15px" }} /> Mobile Number :{" "}
                  {lead?.user?.country_code}-{lead?.user?.mobile_number}
                </Typography>
              </Stack>
              {lead?.user?.job_description && (
                <Stack justifyContent="space-start">
                  <Typography
                    variant="body1"
                    sx={{
                      fontSize: { xs: "15px" },
                      color: "#4d4d4d",
                      fontWeight: "600",
                      display: "flex",
                      alignItems: "center",
                      gap: 1,
                    }}
                  >
                    <DescriptionIcon sx={{ fontSize: "15px" }} /> Job
                    Description : {lead?.user?.job_description}
                  </Typography>
                </Stack>
              )}
            </Box>
            <Grid container>
              <Grid item mb={1} xs={12}>
                <Typography
                  variant="h5"
                  sx={{
                    color: "#4d4d4d",
                    fontWeight: "600",
                    paddingBottom: "10px",
                  }}
                >
                  Skill: {lead?.skill_name}
                </Typography>

                <Stack
                  direction="row"
                  spacing={4}
                  justifyContent="space-start"
                  mb={2}
                >
                  <Typography
                    variant="body1"
                    sx={{
                      fontSize: { xs: "15px" },
                      color: "#4d4d4d",
                      fontWeight: "600",
                      display: "flex",
                      alignItems: "center",
                      gap: 1,
                    }}
                  >
                    Must have skills :{" "}
                    {lead?.must_have?.map((item) => item.name).join(", ")}
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{
                      fontSize: { xs: "15px" },
                      color: "#4d4d4d",
                      fontWeight: "600",
                      display: "flex",
                      alignItems: "center",
                      gap: 1,
                    }}
                  >
                    Good to have skills :{" "}
                    {lead?.nice_to_have?.map((item) => item.name).join(", ")}
                  </Typography>
                </Stack>

                <Stack direction="row" spacing={4} justifyContent="space-start">
                  <Typography
                    variant="body1"
                    sx={{
                      fontSize: { xs: "15px" },
                      color: "#4d4d4d",
                      fontWeight: "600",
                      display: "flex",
                      alignItems: "center",
                      gap: 1,
                    }}
                  >
                    <GroupsIcon sx={{ fontSize: "15px" }} /> Resource Count :{" "}
                    {lead?.resource_count}
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{
                      fontSize: { xs: "15px" },
                      color: "#4d4d4d",
                      fontWeight: "600",
                      display: "flex",
                      alignItems: "center",
                      gap: 1,
                    }}
                  >
                    <CalendarTodayIcon sx={{ fontSize: "15px" }} /> Year Of Exp:{" "}
                    {lead?.year_of_exp}
                  </Typography>
                  {/* <Typography
                          variant="body1"
                          sx={{
                            fontSize: { xs: "15px" },
                            color: "#4d4d4d",
                            fontWeight: "600",
                            display: "flex",
                            alignItems: "center",
                            gap: 1,
                          }}
                        >
                          <LocationCityIcon sx={{ fontSize: "15px" }} /> Work
                          Location : {capitalCase(lead?.work_location_type)}
                        </Typography> */}
                  <Typography
                    variant="body1"
                    sx={{
                      fontSize: { xs: "15px" },
                      color: "#4d4d4d",
                      fontWeight: "600",
                      display: "flex",
                      alignItems: "center",
                      gap: 1,
                    }}
                  >
                    <CurrencyRupeeIcon sx={{ fontSize: "15px" }} />
                    Budget : {lead?.budget}
                  </Typography>
                </Stack>
                <Typography
                  variant="body1"
                  sx={{
                    color: "#4d4d4d",
                    fontWeight: "400",
                    paddingBottom: "10px",
                  }}
                >
                  <span style={{ fontWeight: "bold" }}> Job Description:</span>{" "}
                  {lead?.job_description}
                </Typography>
                {lead?.jd_file_url ? (
                  <Typography
                    variant="body1"
                    sx={{
                      color: "#4d4d4d",
                      fontWeight: "400",
                      paddingBottom: "10px",
                    }}
                  >
                    <a
                      style={{
                        color: "blue",
                        fontWeight: "400",
                        paddingBottom: "10px",
                        textDecoration: "underline",
                      }}
                      target="_black"
                      href={lead?.jd_file_url}
                    >
                      JD File: {lead?.jd_filename}
                    </a>
                  </Typography>
                ) : (
                  <></>
                )}
              </Grid>
            </Grid>
            {/* // ); // })} */}
            <Typography
              variant="body1"
              sx={{
                fontSize: { xs: "16px" },
                color: "#4d4d4d",
                fontWeight: "600",
                textAlign: "right",
              }}
            >
              <AccessTimeIcon sx={{ fontSize: "14px" }} /> Created Date:{" "}
              {DateTime.fromISO(lead.created_at).toFormat("DD")}
            </Typography>

            <Box mt={3}>
              <Typography variant="h4" mb={3}>
                EMB JD:
              </Typography>

              <Grid container>
                <Grid item md={7}>
                  {lead?.lead_line_item_status === "new" ||
                  lead?.lead_line_item_status === "qualification" ? (
                    <Element
                      eletype={inputType.file}
                      label="Upload JD"
                      inputProps={{
                        name: "file",

                        // onChange: (e) => {
                        // formik.setFieldValue("file", e.currentTarget?.files[0]);
                        // },
                        onChange: formik.handleChange,
                        onBlur: formik.handleBlur,
                      }}
                      extraArguments={{
                        filename: "emb-jd.pdf",
                        inputName: params.leadId + "-jd_file_url",
                      }}
                      value={formik.values.file}
                      errorText={formik.touched.file && formik.errors.file}
                      url="/ra/upload_file/jd_file"
                      // fileUploadWithoutUrl={true}
                    />
                  ) : (
                    <p>
                      <span style={{ fontWeight: "bold" }}>EMB JD File:</span>{" "}
                      &nbsp;
                      <a
                        style={{
                          color: "blue",
                          fontWeight: "400",
                          paddingBottom: "10px",
                          textDecoration: "underline",
                        }}
                        target="_black"
                        href={lead?.emb_jd_file_url}
                      >
                        {lead?.emb_jd_filename}
                      </a>
                    </p>
                  )}
                </Grid>
                <Grid item md={7}>
                  {lead?.lead_line_item_status === "new" ||
                  lead?.lead_line_item_status === "qualification" ? (
                    <Element
                      eletype={inputType.textarea}
                      label="Description"
                      inputProps={{
                        name: `description`,
                        onChange: formik.handleChange,
                        onBlur: formik.handleBlur,
                      }}
                      value={formik.values.description}
                      errorText={
                        formik.touched.description && formik.errors.description
                      }
                      cols="4"
                      rows="6"
                    />
                  ) : (
                    <p>
                      <span style={{ fontWeight: "bold" }}>
                        EMB Job Description:
                      </span>{" "}
                      &nbsp;
                      {lead?.emb_job_description}
                    </p>
                  )}
                </Grid>
                <Grid item md={12}>
                  {(lead?.lead_line_item_status === "new" ||
                    lead?.lead_line_item_status === "qualification") && (
                    <Button variant="contained" onClick={handleJdSubmit}>
                      Upload
                    </Button>
                  )}
                </Grid>
              </Grid>
            </Box>
          </CardContent>
        </Card>
        {/* <Card m={4}>
          <CardContent>
            <Grid item md={4} p={3} pt={0}>
              <Element
                eletype={inputType.file}
                label="Upload JD"
                // inputProps={{
                //   name: `skills.${index}.file`,
                //   onChange: (e) =>
                //     formik.setFieldValue(
                //       `skills.${index}.file`,
                //       e.currentTarget?.files[0]
                //     ),
                //   onBlur: formik.handleBlur,
                // }}
                // extraArguments={{
                //   upload_type: "ra_lead",
                // }}
                // value={formik.values.skills[index].file}
                // errorText={
                //   formik.touched.skills?.[index]?.file &&
                //   formik.errors.skills?.[index]?.file
                // }
              />
            </Grid>
            <Grid item md={8}>
              <Element
                eletype={inputType.textarea}
                label="Description"
                // inputProps={{
                //   name: `skills.${index}.description`,
                //   onChange: formik.handleChange,
                //   onBlur: formik.handleBlur,
                // }}
                // value={formik.values.skills[index].description}
                // errorText={
                //   formik.touched.skills?.[index]?.description &&
                //   formik.errors.skills?.[index]?.description
                // }
                cols="4"
                rows="6"
              />
            </Grid>
          </CardContent>
        </Card> */}
      </Grid>

      <Modal
        title="Close Deal"
        open={cdModal}
        onOk={handleCDSubmit}
        onCancel={closeCDModal}
        zIndex={100}
      >
        <Typography variant="h5" mt={4} mb={0}>
          Deal Closing Reason:
        </Typography>
        <Element
          eletype={inputType.textarea}
          // label="Description"
          inputProps={{
            name: `remark`,
            onChange: closeDealFormik.handleChange,
            onBlur: closeDealFormik.handleBlur,
          }}
          value={closeDealFormik.values.remark}
          errorText={
            closeDealFormik.touched.remark && closeDealFormik.errors.remark
          }
          cols="4"
          rows="6"
        />
      </Modal>

      <Modal
        title="Move Deal"
        open={mdModal}
        onOk={handleMDSubmit}
        onCancel={closeMDModal}
        zIndex={100}
        // style={{ paddingTop: "2rem" }}
      >
        {/* <Typography variant="h5" mt={4} mb={0}>
          Deal Closing Reason:
        </Typography> */}
        <Box mt={4}>
          <Element
            eletype={inputType.select}
            label="Select Status"
            inputProps={{
              name: `status`,
              onChange: moveDealFormik.handleChange,
              onBlur: moveDealFormik.handleBlur,
            }}
            value={moveDealFormik.values.status}
            errorText={
              moveDealFormik.touched.status && moveDealFormik.errors.status
            }
            options={lineItemStatus}
          />
          <Element
            eletype={inputType.textarea}
            label="Remark"
            inputProps={{
              name: `remark`,
              onChange: moveDealFormik.handleChange,
              onBlur: moveDealFormik.handleBlur,
            }}
            value={moveDealFormik.values.remark}
            errorText={
              moveDealFormik.touched.remark && moveDealFormik.errors.remark
            }
            cols="4"
            rows="6"
          />
        </Box>
      </Modal>
    </>
  );
}

export default RaDetails;
