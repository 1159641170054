import React, { useEffect, useState } from "react";
import {
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  Card,
  Box,
  Button,
  Modal,
} from "@mui/material";
import Scrollbar from "../../components/Scrollbar";
import ListHead from "../../components/Common/ListHead";
import { capitalCase } from "../../components/Common/change-case";
import Element from "../../components/Form/Element";
import { makeStyles } from "@mui/styles";
import { Ra_EMB_Status } from "../../utils/enum";
import { useDispatch, useSelector } from "react-redux";
import {
  changeStatusOfLeadThunk,
  fetchAllCvsByPartnerThunk,
  fetchAllCvsByInternalThunk,
  fetchAllRALeadsThunk,
  uploadCVByEMBThunk,
} from "../../store/slices/ra/ra.slice";
import { useStyles as formStyles } from ".././../components/Form/Form.theme";
import { useParams } from "react-router-dom";
import { Dropdown, Space } from "antd";
import { idID } from "@mui/material/locale";
import useConfirmDialog from "../../hooks/useConfirmDialog";
import AlertDialog from "../../components/Alert/AlertDialog";

const styleModal = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

export const useStyles = makeStyles({
  dropdownIconCss: {
    "& svg": {
      bottom: "24px !important",
      top: "40px !important",
    },
    "& ul": {
      marginTop: "-32px",
    },
  },
  "@media (max-width:600px)": {
    dropdownIconCss: {
      padding: "0px  !important",
    },
  },
});

const actionOptions = [
  // { value: Ra_EMB_Status.EMB_SCHEDULE_INTERVIEW, label: "EMB Interview" },
  // { value: Ra_EMB_Status.CLIENT_SCHEDULE_INTERVIEW, label: "Client Interview" },
  { value: Ra_EMB_Status.EMB_REJECTED, label: "Rejected" },
  // { value: Ra_EMB_Status.CV_RE_UPLOADED, label: "CV Re-Uploaded" },
];

const TABLE_HEAD = [
  { id: "applicant_name", label: "Candidate Name", alignRight: false },
  { id: "skill", label: "Skills", alignRight: false },
  { id: "vendor_budget", label: "Budget", alignRight: false },
  { id: "cv_filename", label: "CV", alignRight: false },
  { id: "emb_cv_filename", label: "EMB CV", alignRight: false },
  { id: "year_of_exp", label: "Year Of Experience", alignRight: false },
  { id: "actions", label: "Actions / Status", alignRight: false },
];

function RaCvTable({ cvs }) {
  const classes = useStyles();
  const styleClass = formStyles();
  const dispatch = useDispatch();
  const {
    dialogOpen,
    confirm,
    handleClose: handleAlertClose,
    handleConfirm,
  } = useConfirmDialog();

  const lead = useSelector(({ ra }) => ra.leads);
  useEffect(() => {
    dispatch(fetchAllRALeadsThunk());
  }, [dispatch]);

  const [status, setStatus] = useState("");

  useEffect(() => {
    if (lead.data) {
      lead.data.forEach((item, key) => {
        item.lead_line_items.forEach((ele) => {
          if (ele._id === params.leadId) {
            setStatus(ele.lead_line_item_status);
          }
        });
      });
    }
  }, [lead?.data]);

  const [statusSelect, setStatusSelect] = useState();
  const [reason, setReason] = useState(null);
  const [open, setOpen] = useState(false);
  const [openCV, setOpenCV] = useState(false);
  const [selectedApplicantId, setSelectedApplicantId] = useState(null);
  const [file, setFile] = useState(null);
  const [fileError, setFileError] = useState(false);
  const [emb_cost, setEmb_cost] = useState(0);
  const params = useParams();
  const formClasses = formStyles();

  const handleOpen = (id) => {
    setSelectedApplicantId(id);
    setOpen(true);
  };

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleCVModel = (id) => {
    setSelectedApplicantId(id);
    setOpenCV(true);
  };

  const handleClose = () => setOpen(false);

  const handleSelect = (e) => {
    setStatusSelect(e.target.value);
  };

  const handleReason = (e) => {
    setReason(e.target.value);
  };

  const getFilename = () => {
    if (!file) return null;
    const content =
      file.name.length > 20 ? `${file.name.substring(0, 20)}...` : file.name;
    return file.url ? (
      <p className={classes.filename}>
        <a
          href={file.url}
          target="_blank"
          style={{ textDecoration: "none", color: "grey" }}
        >
          {content}
        </a>
      </p>
    ) : (
      <p className={classes.filename}>{content}</p>
    );
  };

  const handleStatusChange = () => {
    dispatch(
      changeStatusOfLeadThunk({
        status: statusSelect,
        remarks: reason,
        lead_id: params.leadId,
        applicant_id: selectedApplicantId,
      })
    )
      .unwrap()
      .then(() => {
        setOpen(false);
        dispatch(fetchAllCvsByPartnerThunk(params.leadId));
        dispatch(fetchAllCvsByInternalThunk(params.leadId));
      });
  };

  const handleCVUpload = () => {
    if (!file) {
      // Assuming 'file' is the state holding the selected file
      setFileError(true); // Show error if no file is selected
      return;
    }
    if (file) {
      const formData = new FormData();
      formData.append("emb_cv_file", file);
      formData.append("emb_cv_filename", file.name);
      formData.append("applicant_emb_cost", emb_cost);
      dispatch(
        uploadCVByEMBThunk({
          id: selectedApplicantId,
          formData: formData,
          leadId: params.leadId,
        })
      )
        .unwrap()
        //moving to client after cv upload
        .then(() => {
          dispatch(
            changeStatusOfLeadThunk({
              status: "move_to_client",
              // remarks: "Moved to Client",
              lead_id: params.leadId,
              applicant_id: selectedApplicantId,
            })
          );
        })
        .finally(() => {
          setOpenCV(false);
          dispatch(fetchAllCvsByPartnerThunk(params.leadId));
          dispatch(fetchAllCvsByInternalThunk(params.leadId));
        });
    }
  };

  const handleMoveToClient = (id) => {
    // const allow = confirm('Are you sure you want to move to client?');
    // if (true) {
    //   dispatch(
    //     changeStatusOfLeadThunk({
    //       status: "move_to_client",
    //       // remarks: "Moved to Client",
    //       lead_id: params.leadId,
    //       applicant_id: id,
    //     })
    //   )
    //     .unwrap()
    //     .then(() => {
    //       dispatch(fetchAllCvsByPartnerThunk(params.leadId));
    //     });
    // }

    const currentItem = cvs.find((item) => item.id === id);
    // console.log(currentItem);

    if (currentItem.emb_cv_filename && currentItem.emb_cv_url) {
      dispatch(
        changeStatusOfLeadThunk({
          status: "move_to_client",
          // remarks: "Moved to Client",
          lead_id: params.leadId,
          applicant_id: id,
        })
      )
        .unwrap()
        .then(() => {
          dispatch(fetchAllCvsByPartnerThunk(params.leadId));
          dispatch(fetchAllCvsByInternalThunk(params.leadId));
        });
    } else {
      handleCVModel(id);
    }
  };

  const handleMoveToHr = async (id) => {
    // if (window.confirm("Do you want move this Cv to Hr round?")) {
    const isConfirmed = await confirm(
      "HR round",
      "Do you want move this Cv to Hr round?"
    );
    if (isConfirmed) {
      dispatch(
        changeStatusOfLeadThunk({
          status: "move_to_hr",
          // remarks: "Moved to Client",
          lead_id: params.leadId,
          applicant_id: id,
        })
      )
        .unwrap()
        .then(() => {
          dispatch(fetchAllCvsByPartnerThunk(params.leadId));
          dispatch(fetchAllCvsByInternalThunk(params.leadId));
        });
    }
  };

  const handleMoveToInterview = async (id) => {
    // if (window.confirm("Do you want move this Cv to Intervue.io round?")) {
    const isConfirmed = await confirm(
      "Intervue.io round",
      "Do you want move this Cv to Intervue.io round?"
    );
    if (isConfirmed) {
      dispatch(
        changeStatusOfLeadThunk({
          status: "move_to_interview.io",
          // remarks: "Moved to Client",
          lead_id: params.leadId,
          applicant_id: id,
        })
      )
        .unwrap()
        .then(() => {
          dispatch(fetchAllCvsByPartnerThunk(params.leadId));
          dispatch(fetchAllCvsByInternalThunk(params.leadId));
        });
    }
  };

  const handleVettingDone = async (id) => {
    // if (window.confirm("Do you want to mark this Cv as Emb Vetted?")) {
    const isConfirmed = await confirm(
      "EMB vetted",
      "Do you want to mark this Cv as Emb Vetted?"
    );
    if (isConfirmed) {
      dispatch(
        changeStatusOfLeadThunk({
          status: "emb_vetted",
          // remarks: "Moved to Client",
          lead_id: params.leadId,
          applicant_id: id,
        })
      )
        .unwrap()
        .then(() => {
          dispatch(fetchAllCvsByPartnerThunk(params.leadId));
          dispatch(fetchAllCvsByInternalThunk(params.leadId));
        });
    }
  };

  // console.log(cvs);

  // const dropdownItems = [
  //   {
  //     key: "1",
  //     label: <a onClick={() => handleCVModel(id)}>Upload CV</a>,
  //   },
  //   {
  //     key: "2",
  //     label: <a>Move To HR</a>,
  //   },
  //   {
  //     key: "3",
  //     label: <a>Move to Interview.io</a>,
  //   },
  //   {
  //     key: "4",
  //     label: <a>Move to Client</a>,
  //   },
  //   {
  //     key: "5",
  //     label: <a onClick={() => handleOpen(id)}>Reject</a>,
  //   },
  // ];

  return (
    <Card>
      <AlertDialog
        open={dialogOpen}
        title="Confirm"
        message="Are you sure you want to continue?"
        onClose={handleAlertClose}
        onConfirm={handleConfirm}
      />
      <Scrollbar>
        <TableContainer
          sx={{ minWidth: 800, padding: "20px", overflowX: "auto" }}
        >
          <Table>
            <ListHead
              rowCount={cvs.length}
              styles={{ backgroundColor: "rgb(243, 246, 248)" }}
              headLabel={TABLE_HEAD}
            />
            <TableBody>
              {cvs.map((row) => {
                const {
                  applicant_name,
                  cv_filename,
                  cv_url,
                  emb_applicant_status,
                  skill_name,
                  vendor_budget,
                  year_of_exp,
                  emb_cv_filename,
                  emb_cv_url,
                  id,
                  client_applicant_status,
                  client_rejection_reason,
                } = row;

                let status = "";

                if (emb_applicant_status.includes("cv")) {
                  let new_arr = [];
                  let statusArr = emb_applicant_status.split("_");
                  for (let i = 0; i < statusArr.length; i++) {
                    if (statusArr[i].includes("cv")) new_arr.push("CV");
                    else new_arr.push(capitalCase(statusArr[i]));
                  }
                  status = new_arr.join(" ");
                } else {
                  status = capitalCase(
                    emb_applicant_status.split("_").join(" ")
                  );
                }

                let items = [];
                // items = [
                //   {
                //     key: "1",
                //     label: (
                //       <span onClick={() => handleCVModel(id)}>Upload CV</span>
                //     ),
                //   },
                //   {
                //     key: "2",
                //     label: <span>Move To HR</span>,
                //   },
                //   {
                //     key: "3",
                //     label: <span>Move to Interview.io</span>,
                //   },
                //   {
                //     key: "4",
                //     label: (
                //       <a onClick={() => handleOpen(id)}>EMB Vetting Done</a>
                //     ),
                //   },
                //   {
                //     key: "5",
                //     label: <a onClick={() => handleOpen(id)}>Reject</a>,
                //   },
                //   {
                //     key: "6",
                //     label: (
                //       <span onClick={() => handleMoveToClient(id)}>
                //         Move to Client
                //       </span>
                //     ),
                //   },
                // ];
                if (
                  emb_applicant_status === "cv_uploaded" ||
                  emb_applicant_status === "cv-vetting"
                ) {
                  items = [
                    {
                      key: "1",
                      label: (
                        <a onClick={() => handleMoveToHr(id)}>Move To HR</a>
                      ),
                    },
                    {
                      key: "2",
                      label: <a onClick={() => handleOpen(id)}>Reject</a>,
                    },
                  ];
                }
                if (emb_applicant_status === "move_to_hr") {
                  items = [
                    {
                      key: "1",
                      label: (
                        <a onClick={() => handleMoveToInterview(id)}>
                          Move to Intervue.io
                        </a>
                      ),
                    },
                    {
                      key: "2",
                      label: <a onClick={() => handleOpen(id)}>Reject</a>,
                    },
                  ];
                }
                if (emb_applicant_status === "move_to_interview.io") {
                  items = [
                    {
                      key: "1",
                      label: (
                        <a onClick={() => handleVettingDone(id)}>
                          EMB Vetting Done
                        </a>
                      ),
                    },
                    {
                      key: "2",
                      label: <a onClick={() => handleOpen(id)}>Reject</a>,
                    },
                  ];
                }
                if (emb_applicant_status === "emb_vetted") {
                  items = [
                    {
                      key: "1",
                      label: (
                        <span onClick={() => handleMoveToClient(id)}>
                          Move to Client
                        </span>
                      ),
                    },
                    {
                      key: "2",
                      label: <a onClick={() => handleOpen(id)}>Reject</a>,
                    },
                  ];
                }

                return (
                  <TableRow hover key={id} tabIndex={-1} role="checkbox">
                    <TableCell align="left">
                      {capitalCase(applicant_name)}
                    </TableCell>
                    <TableCell align="left">
                      {capitalCase(skill_name)}
                    </TableCell>
                    <TableCell align="left">
                      {capitalCase(vendor_budget)}
                    </TableCell>
                    <TableCell noWrap align="left">
                      {cv_url ? (
                        <a target="_blank" href={cv_url}>
                          {capitalCase(cv_filename)}
                        </a>
                      ) : (
                        <>-</>
                      )}
                    </TableCell>
                    <TableCell sx={{ width: "200px" }} align="left">
                      {emb_cv_url ? (
                        <a href={emb_cv_url} target="_blank">
                          {capitalCase(emb_cv_filename)}
                        </a>
                      ) : (
                        <>-</>
                      )}
                    </TableCell>
                    <TableCell align="left">
                      {capitalCase(year_of_exp)}
                    </TableCell>
                    <TableCell
                      sx={{ display: "flex", gap: "5px" }}
                      align="left"
                    >
                      {emb_applicant_status === "emb_rejected" ||
                      emb_applicant_status === "move_to_client" ? (
                        <strong style={{ color: "gray" }}>
                          {capitalCase(
                            emb_applicant_status.split("_").join(" ")
                          )}
                        </strong>
                      ) : (
                        <Space direction="vertical">
                          <Space wrap>
                            <Dropdown menu={{ items }} placement="bottom">
                              {/* <Button>-- Select --</Button> */}
                              <Button>
                                {/* {capitalCase(
                                  emb_applicant_status.split("_").join(" ")
                                )} */}
                                {status}
                              </Button>
                            </Dropdown>
                          </Space>
                        </Space>
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Scrollbar>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleModal}>
          <label className={formClasses.formLabel}>Remarks</label>
          <Box
            sx={{
              display: "flex",
              gap: 2,
              justifyContent: "center",
              flexDirection: "column",
            }}
            className={classes.dropdownIconCss}
          >
            <Element
              eletype="select"
              label="Select status"
              value={statusSelect}
              options={actionOptions}
              inputProps={{
                type: "text",
                onChange: handleSelect,
              }}
            />
            <Element
              label={"Write a reason"}
              eletype="textarea"
              value={reason}
              inputProps={{
                type: "text",
                onChange: handleReason,
              }}
            />
            <Button
              size="large"
              type="submit"
              variant="contained"
              sx={{ marginLeft: "5px" }}
              onClick={handleStatusChange}
            >
              Save
            </Button>
          </Box>
        </Box>
      </Modal>
      <Modal
        open={openCV}
        onClose={() => setOpenCV(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleModal}>
          <Box
            sx={{
              display: "flex",
              gap: 2,
              justifyContent: "center",
              flexDirection: "column",
            }}
            className={classes.dropdownIconCss}
          >
            <div>
              <label className={formClasses.formLabel}>Upload EMB CV*</label>
              <div className="file-upload">
                <div className="file-select">
                  <div className="file-select-button" id="fileName">
                    Upload
                  </div>
                  <div className="file-select-name" id="noFile">
                    Browse File...
                  </div>
                  <input
                    type="file"
                    name="chooseFile"
                    className={formClasses.formControl}
                    onChange={handleFileChange}
                  />
                </div>
              </div>
              {file?.name && (
                <p className={formClasses.filename} style={{ margin: 0 }}>
                  {getFilename()}
                </p>
              )}
              {fileError && (
                <p style={{ color: "red", fontSize: "12px" }}>CV is required</p>
              )}
            </div>

            <div>
              <div className={styleClass.formGroup}>
                <label className={styleClass.formLabel}>Emb Cost</label>

                <input
                  className={styleClass.formControl}
                  // style={styles}
                  value={emb_cost}
                  // placeholder={placeholder}
                  autoComplete="off"
                  onChange={(e) => setEmb_cost(e.target.value)}
                  type="number"
                />
              </div>
            </div>

            <Button
              size="large"
              type="submit"
              variant="contained"
              sx={{ marginLeft: "5px" }}
              onClick={handleCVUpload}
            >
              Save
            </Button>
          </Box>
        </Box>
      </Modal>
    </Card>
  );
}

export default RaCvTable;
