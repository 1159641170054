import React from "react";
import { useStyles } from "./Form.theme";

const UrlInput = ({
  label,
  errorText,
  inputProps,
  value,
  readOnly,
  style,
  icon,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.formGroup}>
      {label ? (
        <label className={classes.formLabel} style={style}>
          {label}
        </label>
      ) : null}
      <div className={classes.urlFormGroup}>
        <select className={classes.urlFormControl}>
          {inputProps?.linkedin ? (
            <>
              <option value="https://www.linkedin.com/">
                https://www.linkedin.com/
              </option>
            </>
          ) : (
            <>
              <option value="https">https://</option>
              <option value="http">http://</option>
            </>
          )}
        </select>
        <input
          className={classes.customFormControl}
          {...inputProps}
          value={value}
          readOnly={readOnly}
          icon={icon}
          autoComplete="off"
          style={style}
        />
      </div>
      {errorText && <span className={classes.formErrorStyle}>{errorText}</span>}
    </div>
  );
};

export default UrlInput;
