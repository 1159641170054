import React, { useEffect, useState, useMemo, useContext } from "react";
import Drawer from "@mui/material/Drawer";
import Filter from "../components/Filter/Filter";
import ListSearchbar from "../components/Common/ListSearchbar";
import plusFill from "@iconify/icons-eva/plus-fill";
import { Icon } from "@iconify/react";
import ListHead from "../components/Common/ListHead";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import Scrollbar from "../components/Common/Scrollbar";
import SearchNotFound from "../../src/components/NotFound/SearchNotFound";
import {
  Grid,
  Card,
  Stack,
  Button,
  Container,
  Chip,
  Typography,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TablePagination,
  Modal,
  Box,
  Tab,
  Tooltip,
} from "@mui/material";
import { css } from "@emotion/css";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAllVendorProfilesThunk,
  deleteVendorProfilesThunk,
  searchVendorProfilesThunk,
} from "../store/slices/vendor/vendor.slice";
import { Link, useNavigate } from "react-router-dom";
import { capitalCase } from "../components/Common/change-case";
import Breadcrumb from "../components/Breadcrumb/Breadcrumb";
import DeleteAction from "../components/Common/DeleteAction";
import _ from "lodash";
import { fetchAllSericesThunk } from "../store/slices/services/service.slice";
import { fetchAllIndustryThunk } from "../store/slices/Industry/industry.slice";
import { fetchAllTechstackThunk } from "../store/slices/teckstack/techstack.slice";
import { fetchAllTagThunk } from "../store/slices/tag/tag.slice";
import useAdvanceFilter from "../hooks/useAdvanceFilter";
import { checkForFilterValidity } from "../utils/global/global";
import { fetchVenderExistThunk } from "../store/slices/vendorExist/notes.slice";
import Element from "../components/Form/Element";
import { inputType, profileTableType } from "../utils/enum";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { validateGST, validatePAN } from "../utils/helper";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { VendorContext } from "../context/vendorProfile.context";
import FilterProfiles from "../components/Filter/FilterProfiles";
import Zoom from "@mui/material/Zoom";
import CSVDownload from "../components/CSVDownload";
import usePermissions from "../hooks/usePermission";
import systemPermissions, { actions } from "../utils/global/permissions.global";
import { ConfigProvider, Progress } from "antd";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: "10px",
  p: 4,
};

const TABLE_HEAD = [
  {
    id: "id",
    label: "Vendor ID",
    alignRight: false,
    //  minWidth: 100
  },
  { id: "brand_name", label: "Brand Name", alignRight: false },
  {
    id: "address",
    label: "Address",
    alignRight: false,
  },
  { id: "services", label: "Services", alignRight: false },
  {
    id: "self_onboarded",
    label: "Self Onboarded ",
    // minWidth: 150,
    alignRight: false,
  },
  {
    id: "created_at",
    label: "Created At",
    minWidth: 100,
    alignRight: false,
    minWidth: 120,
  },
  // { id: "updated_at", label: "Updated At ", minWidth: 100, alignRight: false },
  // {
  //   id: "old_profile_id",
  //   label: "Old Profile Id",
  //   minWidth: 150,
  //   alignRight: false,
  // },
  { id: "profile_stage", label: "Status ", alignRight: false },
  {
    id: "profile_completion",
    label: "Profile Completion ",
    alignRight: false,
    // minWidth: 100,
  },
  // { id: "reason", label: "reason ", alignRight: false },
  // { id: "action", label: "Action ", alignRight: false },
];

function VendorProfile() {
  const [showDrawer, setShowDrawer] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { profiles, totalProfiles } = useSelector(({ vendor }) => vendor);
  const { venderResponseData, isNotExist, message } = useSelector(
    ({ venderExist }) => venderExist
  );
  const [gstRegister, setGstRegister] = useState();
  const [venderPan, setVenderPan] = useState("");
  const [venderGST, setVenderGST] = useState("");
  const [erroGSTPan, setErrorGSTPan] = useState("");
  const { type, changeType } = useContext(VendorContext);

  const userPerm = usePermissions();

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [search, setSearch] = useState("");

  // useEffect(() => {
  //   dispatch(fetchAllVendorProfilesThunk());
  //   dispatch(fetchAllSericesThunk());
  //   dispatch(fetchAllIndustryThunk());
  //   dispatch(fetchAllTechstackThunk());
  //   dispatch(fetchAllTagThunk());
  // }, [dispatch]);

  const [isPermStateStable, setPermStateStable] = useState(false);

  useEffect(() => {
    // Fetch the profile from here
    if (isPermStateStable) {
      dispatch(
        fetchAllVendorProfilesThunk({
          action_type: type,
        })
      );
    }
  }, [type, isPermStateStable]);

  const [isProfileTabEnable, setIsProfileTbEnable] = useState(false);

  useEffect(() => {
    if (userPerm.getProfileTabs().length !== 0) setIsProfileTbEnable(true);
  }, []);

  function handleRowClick(id) {
    if (!isProfileTabEnable) return;
    navigate("/dashboard/profiles/" + id + "/basic");
  }

  function handleAllNewProfile() {
    setGstRegister();
    setVenderGST("");
    setVenderPan("");
    dispatch(fetchVenderExistThunk({}));
    handleOpen();
  }

  function handleDelete(id) {
    dispatch(deleteVendorProfilesThunk(id));
  }

  // const brandData = profiles;
  const [brandData, setBrandData] = useState([]);

  const filterState = useAdvanceFilter({ search });

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  useEffect(() => {
    // if(profiles.length > 0){
    setBrandData(profiles);
    // }
  }, [profiles]);

  const handleChangePage = (_, newPage, limit) => {
    const filterData = checkForFilterValidity(filterState.state);
    if (search || filterData.isAllowed) {
      setPage(newPage);
      return dispatch(
        fetchAllVendorProfilesThunk({
          search,
          page: newPage + 1,
          filter_data: filterData.data,
          search: search,
        })
      );
    }
    dispatch(
      fetchAllVendorProfilesThunk({
        page: newPage + 1,
        action_type: type,
        limit: limit || rowsPerPage,
      })
    );
    setPage(newPage);
  };

  const _searchChange = useMemo(() => {
    return _.debounce((search, filterState) => {
      const filterData = checkForFilterValidity(filterState);
      if (!search) {
        return dispatch(
          fetchAllVendorProfilesThunk({
            action_type: type,
          })
        );
      }
      setPage(0);
      dispatch(
        fetchAllVendorProfilesThunk({
          search: search,
          action_type: type,
        })
      );
    }, 500);
  }, [type]);

  function handleSearchChange(e) {
    const value = e.target.value;
    _searchChange(value, filterState.state);
    setSearch(value);
  }
  function handleFilter() {
    setShowDrawer(true);
  }
  function handleFilterClose() {
    setShowDrawer(false);
  }

  function handleVendorGSTRegister(e) {
    setGstRegister(e.target.value);
    setVenderGST("");
    setVenderPan("");
    dispatch(fetchVenderExistThunk({}));
  }

  function handlePanCheck(e) {
    setErrorGSTPan("");
    setVenderPan(e.target.value);
  }
  function handleGSTCheck(e) {
    setErrorGSTPan("");
    setVenderGST(e.target.value);
  }

  function checkVenderExit(key) {
    if (key === "GST") {
      if (!validateGST(venderGST)) {
        setErrorGSTPan("GST is not valid");
        return;
      }
    } else if (key === "PAN") {
      if (!validatePAN(venderPan)) {
        setErrorGSTPan("Pan is not valid");
        return;
      }
    }
    setErrorGSTPan("");
    const data = {
      search_type: key,
      value: key === "PAN" ? venderPan : key === "GST" ? venderGST : "",
    };
    try {
      dispatch(fetchVenderExistThunk(data));
    } catch (error) {}
  }

  useEffect(() => {
    let hasAccessForApprovedVendors = userPerm.hasAccess(
      systemPermissions["vendorProfileMainPage.approvedVendors"],
      actions.read
    );
    let hasAccessForAllVendors = userPerm.hasAccess(
      systemPermissions["vendorProfileMainPage.allVendors"],
      actions.read
    );

    if (hasAccessForAllVendors && hasAccessForApprovedVendors) {
      return setPermStateStable(true);
    }
    if (hasAccessForAllVendors) {
      setPermStateStable(true);
      return changeType();
    }
    setPermStateStable(true);
  }, []);

  const handleChange = () => {
    changeType();
    setRowsPerPage(10);
    setPage(0);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    handleChangePage(_, 0, event.target.value);
  };

  return (
    <div style={{ padding: "0px 24px" }}>
      <Drawer
        anchor="right"
        open={showDrawer}
        onClose={() => setShowDrawer(false)}
        PaperProps={{
          style: { width: "400px" },
        }}
      >
        {/* <Filter
          filterState={filterState}
          handleFilterClose={handleFilterClose}
        /> */}
        <FilterProfiles handleFilterClose={handleFilterClose} />
      </Drawer>

      <Stack
        mb={2}
        justifyContent="space-between"
        direction="row"
        alignItems="center"
      >
        <Typography variant="h4" gutterBottom>
          Select vendor profile to change
        </Typography>

        {type === profileTableType.all &&
          userPerm.hasAccess(
            systemPermissions["vendorProfileMainPage.allVendors"],
            actions.create
          ) && (
            <Button
              size="large"
              variant="contained"
              startIcon={<Icon icon={plusFill} />}
              onClick={handleAllNewProfile}
            >
              Add More
            </Button>
          )}
      </Stack>

      {/* <Card>
        <Breadcrumb />
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{
            display: { lg: "flex", md: "flex", sm: "block", xs: "block" },
          }}
        >
          <ListSearchbar
            placeholder="Search vendor profile"
            handleSearchChange={handleSearchChange}
            styles={{
              width: "280px",
            }}
          />

          <Button
            variant="outlined"
            sx={{ padding: "10px 20px", marginRight: "20px" }}
            onClick={handleFilter}
          >
            <Icon icon="bytesize:filter" />{" "}
            <span style={{ marginLeft: "10px" }}>Filter</span>
          </Button>
        </Stack>

        <Scrollbar>
          {brandData.length > 0 ? (
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <ListHead
                  headLabel={TABLE_HEAD}
                  rowCount={brandData?.length}
                  styles={{ backgroundColor: "rgb(243, 246, 248)" }}
                />
                <TableBody>
                  {brandData?.map((row) => {
                    const {
                      id,
                      org_id,
                      brand_name,
                      address,
                      // registered_business_name,
                      // mobile_number,
                      services,
                      self_onboarded,
                      created_at,
                      // updated_at,
                      verified,
                      // old_profile_id,
                      profile_stage,
                    } = row;

                    const serviceList = services
                      ?.map((service) => service.name)
                      .join(", ") || '';

                    return (
                      <TableRow
                        hover
                        key={id}
                        tabIndex={-1}
                        role="checkbox"
                        sx={{ cursor: "pointer", zIndex: "" }}
                        onClick={() => handleRowClick(id, org_id)}
                      >
                        <TableCell align="left">{id}</TableCell>
                        <TableCell>
                          <Typography noWrap sx={{ fontSize: "15px" }}>
                            <Link
                              to={"/"}
                              style={{
                                textDecoration: "none",
                                color: "black",
                              }}
                            >
                              {capitalCase(brand_name)}
                            </Link>
                          </Typography>
                        </TableCell>
                        <TableCell align="left">{address || "-"}</TableCell>

                        <TableCell align="left">
                          <Tooltip title={serviceList}>
                            {serviceList.substring(0, 32)}...
                          </Tooltip>
                        </TableCell>

                        <TableCell align="left">
                          <Chip label={self_onboarded ? "TRUE" : "FALSE"} />
                        </TableCell>
                        <TableCell align="left">{created_at}</TableCell>
                        <TableCell align="left">
                          {profile_stage == "pending" ? (
                            "Pending"
                          ) : profile_stage == "accounts_approved" ? (
                            "Finance Approved"
                          ) : profile_stage == "unapproved_by_accounts" ? (
                            "Unapproved By Finance"
                          ) : profile_stage == "sent_for_accounts_approval" ? (
                            "sent for finance approval"
                          ) : profile_stage == "verified" ? (
                            <Chip
                              className={css`
                                font-size: 12px;
                                border: none;
                                text-transform: capitalize;
                                padding-left: 5px;
                                padding-right: 5px;
                              `}
                              size="small"
                              variant="outlined"
                              label={<CheckCircleOutlineIcon />}
                              sx={{
                                color: "#11a54a",
                              }}
                            />
                          ) : profile_stage == "unverified" ? (
                            <Chip
                              className={css`
                                font-size: 12px;
                                border: none;
                                text-transform: capitalize;
                                padding-left: 5px;
                                padding-right: 5px;
                              `}
                              size="small"
                              variant="outlined"
                              label={<CancelOutlinedIcon />}
                              sx={{
                                color: "#fe4842",
                              }}
                            />
                          ) : (
                            ""
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      count={totalProfiles}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      rowsPerPageOptions={false}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          ) : (
            <SearchNotFound
              src="/static/support.svg"
              subtitle="There is currently no vendors data to show."
            />
          )}
        </Scrollbar>
      </Card> */}

      <TabContext value={type}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList onChange={handleChange}>
            {userPerm.hasAccess(
              systemPermissions["vendorProfileMainPage.approvedVendors"],
              actions.read
            ) && (
              <Tab label="Approved Vendors" value={profileTableType.active} />
            )}
            {userPerm.hasAccess(
              systemPermissions["vendorProfileMainPage.allVendors"],
              actions.read
            ) && <Tab label="All Vendors" value={profileTableType.all} />}
          </TabList>
        </Box>
        <TabPanel value={profileTableType.active}>
          <Card>
            <Breadcrumb />
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              sx={{
                display: { lg: "flex", md: "flex", sm: "block", xs: "block" },
              }}
            >
              <ListSearchbar
                placeholder="Search vendor profile"
                handleSearchChange={handleSearchChange}
                styles={{
                  width: "280px",
                }}
              />

              <div style={{ display: "flex", gap: "16px" }}>
                {userPerm.hasAccess(
                  systemPermissions["vendorProfileMainPage.approvedVendors"],
                  actions.export
                ) && <CSVDownload headers={TABLE_HEAD} data={brandData} />}
                {userPerm.hasAccess(
                  systemPermissions["vendorProfileMainPage.filter"],
                  actions.read
                ) && (
                  <Button
                    variant="outlined"
                    sx={{ padding: "10px 20px", marginRight: "20px" }}
                    onClick={handleFilter}
                  >
                    <Icon icon="bytesize:filter" />{" "}
                    <span style={{ marginLeft: "10px" }}>Filter</span>
                  </Button>
                )}
              </div>
            </Stack>

            <div>
              {brandData.length > 0 ? (
                <>
                  <TableContainer sx={{ minWidth: 800 }}>
                    <Table stickyHeader aria-label="sticky table">
                      <ListHead
                        headLabel={TABLE_HEAD}
                        rowCount={brandData?.length}
                        styles={{ backgroundColor: "rgb(243, 246, 248)" }}
                      />
                      <TableBody>
                        {brandData?.map((row) => {
                          const {
                            id,
                            org_id,
                            brand_name,
                            address,
                            // registered_business_name,
                            // mobile_number,
                            services,
                            self_onboarded,
                            created_at,
                            // updated_at,
                            // old_profile_id,
                            profile_stage,
                            city,
                            state,
                            profile_progress_count,
                          } = row;

                          const serviceList = services?.join(", ") || "";
                          let addressData = "";

                          if (city && state)
                            addressData =
                              capitalCase(city) + ", " + capitalCase(state);
                          else if (city && !state)
                            addressData = capitalCase(city);
                          else if (!city && state)
                            addressData = capitalCase(state);
                          else if (!city && !state) addressData = "-";

                          return (
                            <TableRow
                              hover
                              key={id}
                              tabIndex={-1}
                              role="checkbox"
                              sx={{ cursor: "pointer", zIndex: "" }}
                              onClick={() => handleRowClick(id, org_id)}
                            >
                              <TableCell align="left">{id}</TableCell>
                              <TableCell>
                                <Typography noWrap sx={{ fontSize: "15px" }}>
                                  <Link
                                    to={"/"}
                                    style={{
                                      textDecoration: "none",
                                      color: "black",
                                    }}
                                  >
                                    {/* {capitalCase(
                                      brand_name.length > 20
                                        ? `${brand_name.substring(0, 20)}...`
                                        : brand_name
                                    )} */}
                                    {brand_name.length > 20 ? (
                                      <Tooltip
                                        title={brand_name}
                                        TransitionComponent={Zoom}
                                      >
                                        {brand_name.substring(0, 20) + "..."}
                                      </Tooltip>
                                    ) : (
                                      capitalCase(brand_name) || "-"
                                    )}
                                  </Link>
                                </Typography>
                              </TableCell>
                              <TableCell align="left">
                                {/* {addressData || "-"} */}
                                {addressData.length > 20 ? (
                                  <Tooltip
                                    title={addressData}
                                    TransitionComponent={Zoom}
                                  >
                                    {addressData.substring(0, 20) + "..."}
                                  </Tooltip>
                                ) : (
                                  capitalCase(addressData) || "-"
                                )}
                              </TableCell>

                              <TableCell align="left">
                                {serviceList.length > 32 ? (
                                  <Tooltip
                                    title={serviceList}
                                    TransitionComponent={Zoom}
                                  >
                                    {serviceList.substring(0, 32) + "..."}
                                  </Tooltip>
                                ) : (
                                  serviceList || "-"
                                )}
                              </TableCell>

                              <TableCell align="left">
                                <Chip
                                  label={self_onboarded ? "TRUE" : "FALSE"}
                                />
                              </TableCell>
                              <TableCell align="left">{created_at}</TableCell>
                              <TableCell align="left">
                                <Chip
                                  label={
                                    profile_stage == "pending"
                                      ? "Pending"
                                      : profile_stage == "accounts_approved"
                                      ? "Finance Approved"
                                      : profile_stage ==
                                        "unapproved_by_accounts"
                                      ? "Not approved By Finance"
                                      : profile_stage ==
                                        "sent_for_accounts_approval"
                                      ? "Sent For Finance Approval"
                                      : profile_stage == "approved"
                                      ? "Approved"
                                      : profile_stage == "unverified"
                                      ? "Unverified"
                                      : ""
                                  }
                                />
                              </TableCell>
                              <TableCell align="left">
                                <ConfigProvider
                                  theme={{
                                    components: {
                                      Progress: {
                                        /* here is your component tokens */
                                        circleTextColor: "black",
                                        defaultColor: "rgb(0, 171, 85)",
                                      },
                                    },
                                  }}
                                >
                                  <Progress
                                    type="dashboard"
                                    percent={Math.ceil(
                                      Number(profile_progress_count)
                                    )}
                                    gapDegree={30}
                                    size={"small"}
                                    circleTextColor={"white"}
                                    status="normal"
                                  />
                                </ConfigProvider>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                      {/* <TableFooter>
                        <TableRow>
                          <TablePagination
                            count={totalProfiles}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                          />
                        </TableRow>
                      </TableFooter> */}
                    </Table>
                  </TableContainer>
                  <TablePagination
                    component="div"
                    count={totalProfiles}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </>
              ) : (
                <SearchNotFound
                  src="/static/support.svg"
                  subtitle="There is currently no vendors data to show."
                />
              )}
            </div>
          </Card>
        </TabPanel>
        <TabPanel value={profileTableType.all}>
          <Card>
            <Breadcrumb />
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              sx={{
                display: { lg: "flex", md: "flex", sm: "block", xs: "block" },
              }}
            >
              <ListSearchbar
                placeholder="Search vendor profile"
                handleSearchChange={handleSearchChange}
                styles={{
                  width: "280px",
                }}
              />
              <div style={{ display: "flex", gap: "16px" }}>
                {userPerm.hasAccess(
                  systemPermissions["vendorProfileMainPage.allVendors"],
                  actions.export
                ) && <CSVDownload headers={TABLE_HEAD} data={brandData} />}
                {userPerm.hasAccess(
                  systemPermissions["vendorProfileMainPage.filter"],
                  actions.read
                ) && (
                  <Button
                    variant="outlined"
                    sx={{ padding: "10px 20px", marginRight: "20px" }}
                    onClick={handleFilter}
                  >
                    <Icon icon="bytesize:filter" />{" "}
                    <span style={{ marginLeft: "10px" }}>Filter</span>
                  </Button>
                )}
              </div>
            </Stack>

            <div>
              {brandData.length > 0 ? (
                <>
                  <TableContainer sx={{ minWidth: 800 }}>
                    <Table stickyHeader aria-label="sticky table">
                      <ListHead
                        headLabel={TABLE_HEAD}
                        rowCount={brandData?.length}
                        styles={{ backgroundColor: "rgb(243, 246, 248)" }}
                      />
                      <TableBody>
                        {brandData?.map((row) => {
                          const {
                            id,
                            org_id,
                            brand_name,
                            address,
                            // registered_business_name,
                            // mobile_number,
                            services,
                            self_onboarded,
                            created_at,
                            // updated_at,
                            // old_profile_id,
                            profile_stage,
                            city,
                            state,
                            profile_progress_count,
                          } = row;

                          const serviceList = services?.join(", ") || "";
                          let addressData = "";

                          if (city && state)
                            addressData =
                              capitalCase(city) + ", " + capitalCase(state);
                          else if (city && !state)
                            addressData = capitalCase(city);
                          else if (!city && state)
                            addressData = capitalCase(state);
                          else if (!city && !state) addressData = "-";

                          return (
                            <TableRow
                              hover
                              key={id}
                              tabIndex={-1}
                              role="checkbox"
                              sx={{ cursor: "pointer", zIndex: "" }}
                              onClick={() => handleRowClick(id, org_id)}
                            >
                              <TableCell align="left">{id}</TableCell>
                              <TableCell>
                                <Typography noWrap sx={{ fontSize: "15px" }}>
                                  <Link
                                    to={"/"}
                                    style={{
                                      textDecoration: "none",
                                      color: "black",
                                    }}
                                  >
                                    {/* {capitalCase(brand_name)} */}
                                    {brand_name.length > 20 ? (
                                      <Tooltip
                                        title={brand_name}
                                        TransitionComponent={Zoom}
                                      >
                                        {brand_name.substring(0, 20) + "..."}
                                      </Tooltip>
                                    ) : (
                                      capitalCase(brand_name) || "-"
                                    )}
                                  </Link>
                                </Typography>
                              </TableCell>
                              <TableCell align="left">
                                {/* {addressData || "-"} */}
                                {addressData.length > 20 ? (
                                  <Tooltip
                                    title={addressData}
                                    TransitionComponent={Zoom}
                                  >
                                    {addressData.substring(0, 20) + "..."}
                                  </Tooltip>
                                ) : (
                                  capitalCase(addressData) || "-"
                                )}
                              </TableCell>

                              <TableCell align="left">
                                {serviceList.length > 32 ? (
                                  <Tooltip
                                    title={serviceList}
                                    TransitionComponent={Zoom}
                                  >
                                    {serviceList.substring(0, 32) + "..."}
                                  </Tooltip>
                                ) : (
                                  serviceList || "-"
                                )}
                              </TableCell>

                              <TableCell align="left">
                                <Chip
                                  label={self_onboarded ? "TRUE" : "FALSE"}
                                />
                              </TableCell>
                              <TableCell align="left">{created_at}</TableCell>
                              <TableCell align="left">
                                <Chip
                                  label={
                                    profile_stage == "pending"
                                      ? "Pending"
                                      : profile_stage == "accounts_approved"
                                      ? "Finance Approved"
                                      : profile_stage ==
                                        "unapproved_by_accounts"
                                      ? "Not Approved By Finance"
                                      : profile_stage ==
                                        "sent_for_accounts_approval"
                                      ? "Sent For Finance Approval"
                                      : profile_stage == "approved"
                                      ? "Approved"
                                      : profile_stage == "unverified"
                                      ? "Unverified"
                                      : ""
                                  }
                                />
                              </TableCell>
                              <TableCell align="left">
                                <ConfigProvider
                                  theme={{
                                    components: {
                                      Progress: {
                                        /* here is your component tokens */
                                        circleTextColor: "black",
                                        defaultColor: "rgb(0, 171, 85)",
                                      },
                                    },
                                  }}
                                >
                                  <Progress
                                    type="dashboard"
                                    percent={Math.ceil(
                                      Number(profile_progress_count)
                                    )}
                                    gapDegree={30}
                                    size={"small"}
                                    circleTextColor={"white"}
                                    status="normal"
                                  />
                                </ConfigProvider>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                      {/* <TableFooter>
                        <TableRow>
                          <TablePagination
                            count={totalProfiles}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                          />
                        </TableRow>
                      </TableFooter> */}
                    </Table>
                  </TableContainer>
                  <TablePagination
                    component="div"
                    count={totalProfiles}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </>
              ) : (
                <SearchNotFound
                  src="/static/support.svg"
                  subtitle="There is currently no vendors data to show."
                />
              )}
            </div>
          </Card>
        </TabPanel>
      </TabContext>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box item lg={6} md={6}>
            <Element
              label="Vendor is GST register"
              value={gstRegister}
              options={[
                {
                  label: "Yes",
                  value: "yes",
                },
                {
                  label: "No",
                  value: "no",
                },
              ]}
              eletype={inputType.select}
              inputProps={{
                type: "text",
                onChange: handleVendorGSTRegister,
              }}
              icons={
                <ArrowDropDownIcon
                  sx={{ position: "absolute", right: 20, bottom: 24 }}
                />
              }
            />
          </Box>
          <Box>
            {gstRegister === "no" ? (
              <>
                <Box
                  sx={{
                    color: "#212b36",
                    fontSize: "0.9rem",
                    fontWeight: "bold",
                    mb: "0.5rem",
                    mt: "1rem",
                  }}
                >
                  Enter Vendor Pan
                </Box>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Box>
                    <Element
                      label=""
                      value={venderPan}
                      eletype={inputType.input}
                      inputProps={{
                        placeholder: "Enter Vendor Pan",
                        type: "text",
                        name: "venderPan",
                        onChange: handlePanCheck,
                      }}
                    />
                    <>
                      {erroGSTPan ? (
                        <Box
                          mt={-2}
                          ml={1}
                          sx={{ fontSize: "12px", color: "red" }}
                        >
                          {erroGSTPan}
                        </Box>
                      ) : (
                        <></>
                      )}
                    </>
                  </Box>
                  <Button
                    size="large"
                    variant="contained"
                    disabled={venderPan == "" || venderPan == undefined}
                    sx={{ marginLeft: "5px" }}
                    onClick={() => checkVenderExit("PAN")}
                  >
                    check pan
                  </Button>
                  {/* <Box sx={{ ml: "30px" }}>
                    <a
                      target="_blank"
                      href={`/dashboard/profiles/${venderResponseData?.id}/basic`}
                    >
                      {venderResponseData?.brand_name}
                    </a>
                  </Box> */}
                </Box>
              </>
            ) : gstRegister === "yes" ? (
              <>
                <Box
                  sx={{
                    color: "#212b36",
                    fontSize: "0.9rem",
                    fontWeight: "bold",
                    mb: "0.5rem",
                    mt: "1rem",
                  }}
                >
                  Enter Vendor GST Details
                </Box>

                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Box>
                    <Element
                      label=""
                      value={venderGST}
                      eletype={inputType.input}
                      inputProps={{
                        placeholder: "Enter Vendor GST",
                        type: "text",
                        name: "venderGst",
                        onChange: handleGSTCheck,
                      }}
                    />
                    <>
                      {erroGSTPan ? (
                        <Box
                          mt={-2}
                          ml={1}
                          sx={{ fontSize: "12px", color: "red" }}
                        >
                          {erroGSTPan}
                        </Box>
                      ) : (
                        <></>
                      )}
                    </>
                  </Box>
                  <Button
                    size="large"
                    variant="contained"
                    disabled={venderGST == "" || venderGST == undefined}
                    sx={{ marginLeft: "5px" }}
                    onClick={() => checkVenderExit("GST")}
                  >
                    check GST
                  </Button>
                  {/* <Box sx={{ ml: "30px" }}>
                    <a
                      target="_blank"
                      href={`/dashboard/profiles/${venderResponseData?.id}/basic`}
                    >
                      {venderResponseData?.brand_name}
                    </a>
                  </Box> */}
                </Box>
              </>
            ) : (
              <></>
            )}
          </Box>
          {venderResponseData?.brand_name ? (
            <Box
              sx={{
                mt: "20px",
                mx: "auto",
                width: "fit-content",
                py: "5px",
                px: "10px",
                border: "1px solid green",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "5px",
                bgcolor: "#00AB55",
              }}
            >
              <a
                style={{
                  color: "white",
                  fontWeight: "bold",
                }}
                target="_blank"
                href={`/dashboard/profiles/${venderResponseData?.id}/basic`}
              >
                {venderResponseData?.brand_name}
              </a>
            </Box>
          ) : isNotExist ? (
            <>
              <Box
                sx={{
                  mt: "20px",
                  mx: "auto",
                  width: "fit-content",
                  py: "5px",
                  px: "10px",
                  border: "1px solid green",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "5px",
                  bgcolor: "#00AB55",
                }}
              >
                <a
                  style={{
                    color: "white",
                    fontWeight: "bold",
                  }}
                  href={`/dashboard/profiles/new/basic`}
                >
                  Add New Vendor
                </a>
              </Box>
            </>
          ) : (
            <></>
          )}
        </Box>
      </Modal>
    </div>
  );
}

export default VendorProfile;
