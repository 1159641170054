import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchRaLeadByIdThunk } from "../../store/slices/ra/ra.slice";
import { useParams, Outlet, useNavigate } from "react-router-dom";
import {
  allVendor,
  basicInfoPath,
  cvPath,
  dealTimelimePath,
  shortlist,
  shortlistedVendors,
} from "../../utils/constant";
import {
  Container,
  Grid,
  Box,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import CustomTabs from "../../components/Common/Tabs";

function RaDetailsFull() {
  const labels = [
    {
      label: "Basic",
      value: basicInfoPath,
      icon: "/static/icons/overview.svg",
      activeIcon: "/static/icons/overview_active.svg",
    },
    {
      label: "Shortlist",
      value: shortlist,
      icon: "/static/icons/expertise.svg",
      activeIcon: "/static/icons/expertise_active.svg",
      childTabs: [
        {
          label: "All Vendors",
          value: allVendor,
          icon: "/static/icons/address.svg",
          activeIcon: "/static/icons/address_active.svg",
        },
        {
          label: "Shortlisted Vendors",
          value: shortlistedVendors,
          icon: "/static/icons/address.svg",
          activeIcon: "/static/icons/address_active.svg",
        },
        // {
        //   label: "POC",
        //   value: poc,
        //   icon: "/static/icons/POC.svg",
        //   activeIcon: "/static/icons/POC_active.svg",
        // },
        // {
        //   label: "Founders Information",
        //   value: founderInfo,
        //   icon: "/static/icons/expertise.svg",
        //   activeIcon: "/static/icons/expertise_active.svg",
        // },
      ],
    },
    {
      label: "CV",
      value: cvPath,
      icon: "/static/icons/expertise.svg",
      activeIcon: "/static/icons/expertise_active.svg",
    },
    // {
    //   label: "Timeline",
    //   value: dealTimelimePath,
    //   icon: "/static/icons/expertise.svg",
    //   activeIcon: "/static/icons/expertise_active.svg",
    // },
  ];

  return (
    <>
      <Grid>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <CustomTabs labels={labels} />
        </Box>
        {/* {isLoading && (
            <TabPanel value={selectedTab} index={0}>
              <div style={{ position: "relative" }}>
                <Loader bg="rgba(255,255,255)" height="60vh" />
              </div>
            </TabPanel>
          )} */}
        <div style={{ marginBottom: "20px" }}></div>
        <Outlet />
      </Grid>
    </>
  );
}

export default RaDetailsFull;
