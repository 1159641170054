import * as React from "react";
import {
  Button,
  Box,
  Card,
  Table,
  TableContainer,
  TableBody,
  TableRow,
  TableCell,
  TableFooter,
  TablePagination,
} from "@mui/material";
import plusFill from "@iconify/icons-eva/plus-fill";
import { Icon } from "@iconify/react";
import Modal from "../components/Modal/Modal";
import { useState, useEffect } from "react";
import AddressModal from "../components/Modal/AddressModal";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteBusinessAddress,
  fetchAllAddressThunk,
} from "../store/slices/vendor/vendor.slice";
import ListHead from "../components/Common/ListHead";
import Scrollbar from "../components/Common/Scrollbar";
import useBusinessId from "../hooks/useBusinessId";
import DeleteAction from "../components/Common/DeleteAction";
import withBusinessId from "../hoc/withBusinessId";
import SearchNotFound from "../../src/components/NotFound/SearchNotFound";
import usePermissions from "../hooks/usePermission";
import systemPermissions, { actions } from "../utils/global/permissions.global";

const TABLE_HEAD = [
  { id: "address_line1", label: "Address", alignRight: false },
  // { id: "address_line2", label: "Address Line 2", alignRight: false },
  { id: "city", label: "City", alignRight: false },
  { id: "state", label: "State", alignRight: false },
  { id: "country", label: "Country", alignRight: false },
  { id: "zip", label: "Zip Code", alignRight: false },
  { id: "action", label: "Action ", alignRight: false },
];

function DataGridDemo() {
  const [showModal, setShowModal] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const handleOpen = () => {
    setShowModal(true);
  };

  // --new for pagination starts here---

  const [page, setPage] = React.useState(0);
  const [rowsPerPage] = React.useState(10);

  const userPerm = usePermissions();

  const handleChangePage = (_, newPage) => {
    dispatch(fetchAllAddressThunk(newPage + 1));
    setPage(newPage);
  };

  // --new for pagination ends here---

  const handleClose = () => {
    setShowModal(false);
    setSelectedData(null);
  };
  const dispatch = useDispatch();
  const businessId = useBusinessId();
  const { allAddress, totalAddress } = useSelector(({ vendor }) => vendor);

  // console.log('allAddress', allAddress);

  useEffect(() => {
    dispatch(fetchAllAddressThunk());
  }, [dispatch, businessId]);

  useEffect(() => {
    if (page != 0) {
      dispatch(fetchAllAddressThunk(page));
      setPage(parseInt(page - 1));
    }
  }, [allAddress.length == 0]);

  function handleEdit(row) {
    if (
      !userPerm.hasAccess(
        systemPermissions["vendorProfileDetailPage.address"],
        actions.edit
      )
    )
      return;
    if (allAddress?.[0].profile_stage !== "sent_for_accounts_approval") {
      setSelectedData(row);
      setShowModal(true);
    }
  }

  function handleDelete(id) {
    dispatch(deleteBusinessAddress({ id: id, page: 1 }));
  }

  return (
    <>
      <div style={{ height: 400, width: "100%" }}>
        {userPerm.hasAccess(
          systemPermissions["vendorProfileDetailPage.address"],
          actions.create
        ) && (
          <Box
            sx={{
              display: "flex",
              marginBottom: "30px",
              justifyContent: "end",
            }}
          >
            <Button
              variant="contained"
              startIcon={<Icon icon={plusFill} />}
              onClick={handleOpen}
            >
              Add Address
            </Button>
          </Box>
        )}

        <Card>
          {/* <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{
              display: { lg: "flex", md: "flex", sm: "block", xs: "block" },
            }}
          >
            <ListSearchbar placeholder="Search ...." />
          </Stack> */}

          <Scrollbar>
            {allAddress.length > 0 ? (
              <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                  <ListHead
                    headLabel={TABLE_HEAD}
                    rowCount={allAddress?.length}
                    styles={{ backgroundColor: "rgb(243, 246, 248)" }}
                  />
                  <TableBody>
                    {allAddress?.map((row) => {
                      const {
                        id,
                        address_line1,
                        address_line2,
                        city,
                        state,
                        country,
                        zip_code,
                      } = row;

                      return (
                        <TableRow
                          hover
                          key={id}
                          tabIndex={-1}
                          role="checkbox"
                          sx={{ cursor: "pointer" }}
                        >
                          <TableCell
                            onClick={() => handleEdit(row)}
                            align="left"
                          >
                            {address_line1}
                          </TableCell>
                          {/* <TableCell
                            onClick={() => handleEdit(row)}
                            align="left"
                          >
                            {address_line2}
                          </TableCell> */}
                          <TableCell
                            onClick={() => handleEdit(row)}
                            align="left"
                          >
                            {city}
                          </TableCell>
                          <TableCell
                            onClick={() => handleEdit(row)}
                            align="left"
                          >
                            {state}
                          </TableCell>
                          <TableCell
                            onClick={() => handleEdit(row)}
                            align="left"
                          >
                            {country}
                          </TableCell>
                          <TableCell
                            onClick={() => handleEdit(row)}
                            align="left"
                          >
                            {zip_code}
                          </TableCell>
                          <TableCell align="left">
                            {userPerm.hasAccess(
                              systemPermissions[
                                "vendorProfileDetailPage.address"
                              ],
                              actions.create
                            ) ? (
                              <DeleteAction
                                handleDelete={() => handleDelete(row.id)}
                              />
                            ) : (
                              "-"
                            )}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                  <TableFooter>
                    <TableRow>
                      <TablePagination
                        count={totalAddress}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        rowsPerPageOptions={false}
                      />
                    </TableRow>
                  </TableFooter>
                </Table>
              </TableContainer>
            ) : (
              <SearchNotFound
                src="/static/support.svg"
                subtitle="There is currently no address to show."
              />
            )}
          </Scrollbar>
        </Card>
      </div>
      <Modal
        isOpen={showModal}
        closeModal={handleClose}
        title="Add Address"
        content={
          <AddressModal
            data={selectedData}
            closeModal={handleClose}
            page={page == 0 ? 1 : page + 1}
          />
        }
        dialogProps={{ fullWidth: true }}
        titleStyle={{ textAlign: "center", marginTop: 2 }}
        actionStyle={{ justifyContent: "center", marginBottom: 2 }}
      />
    </>
  );
}

export default withBusinessId(DataGridDemo);
