import React, { useEffect, useState } from "react";
import { vendorAxios } from "../../utils/axios/vendor.axios";

import { useStyles } from "./Form.theme";

function FileInput({
  label,
  onChange,
  onBlur,
  name,
  value = "",
  errorText,
  url = "/vendor/upload_business_files",
  extraArguments = {},
  multiple = false,
  disabled,
  fileUploadWithoutUrl,
}) {
  const classes = useStyles();
  const [file, setFile] = useState(() => {
    if (!value) {
      return multiple ? [] : "";
    }
    return value;
  });

  // console.log("file, value:", file, value);


  useEffect(() => {
    if (multiple && !value) setFile([]);
    if (!multiple && !value) setFile("");
    if (value) setFile(value);
  }, [value]);

  // console.log(file, "file::", value);


  useEffect(() => {
    if (multiple && !value) {
      onChange({
        target: {
          name,
          value: [],
        },
      });
    }
    if (!multiple && !value?.filename) {
      onChange({
        target: {
          name,
          value: {
            url: "",
          },
        },
      });
    }
  }, []);

  async function handleChange(e) {
    const currentFile = e.target.files[0];
    if (!fileUploadWithoutUrl) {
      const formData = new FormData();
      formData.append("file", currentFile); // Append the file to the FormData object
      Object.keys(extraArguments).forEach((key) => {
        formData.append(key, extraArguments[key]); // Append additional arguments
      });
      const response = await vendorAxios.post(url, formData);
      if (multiple) {
        setFile((data) => [...data, response.data]);
        onChange({
          target: {
            name,
            value: [...file, response.data],
          },
        });
      } else {
        setFile(response.data);
        onChange({
          target: {
            name,
            value: response.data,
          },
        });
      }
    } else {
      setFile(currentFile);
      onChange({
        target: {
          name,
          value: currentFile,
        },
      });
    }
  }
  function getFilename() {
    // console.log("file:-", file);

    var content = "";
    if (file.filename?.length > 30) {
      content = file?.filename?.substring(0, 30) + "...";
    } else content = file.filename || file?.name;
    if (file.url) {
      return (
        <p className={classes.filename}>
          <a
            href={file.url}
            target="_blank"
            style={{ textDecoration: "none", color: "grey" }}
          >
            {content}
          </a>
        </p>
      );
    }
    return <p className={classes.filename}>{content}</p>;
  }

  // console.log("File : ", file);
  // console.log("value : ", value);

  return (
    <>
      <div className={classes.formGroup}>
        <label className={classes.formLabel}>{label}</label>
        <div class="file-upload">
          <div class="file-select">
            <div class="file-select-button" id="fileName">
              Choose File
            </div>
            <div class="file-select-name" id="noFile">
              Upload File...
            </div>
            <input
              type="file"
              name="chooseFile"
              className={classes.formControl}
              onChange={handleChange}
              onBlur={onBlur}
              accept=".jpeg,.jpg,.png,.pdf"
              disabled={disabled}
            />
          </div>
        </div>
        {multiple &&
          file?.map((val) => {
            return (
              <a href={val.url} className={classes.filename} target="_blank">
                {val.filename}
              </a>
            );
          })}
        {(file?.filename || file?.name) && (
          <p className={classes.filename}>{getFilename()}</p>
        )}
        {errorText && (
          <span className={classes.formErrorStyle}>{errorText}</span>
        )}
      </div>
    </>
  );
}

export default FileInput;
