import { Icon } from "@iconify/react";
import pieChart2Fill from "@iconify/icons-eva/pie-chart-2-fill";
import peopleFill from "@iconify/icons-eva/people-fill";
import personFill from "@iconify/icons-eva/person-fill";
import infofill from "@iconify/icons-eva/info-fill";
import settingsfill from "@iconify/icons-eva/settings-2-fill";
import searchFill from "@iconify/icons-eva/search-fill";
import currency from "@iconify/icons-eva/checkmark-circle-2-outline";

// ----------------------------------------------------------------------

const getIcon = (name) => <Icon icon={name} width={22} height={22} />;

const sidebarConfig = [
  {
    title: "dashboard",
    path: "/dashboard/app",
    icon: getIcon(pieChart2Fill),
  },
  {
    title: "profile",
    path: "/dashboard/myProfile",
    icon: getIcon(personFill),
  },
  {
    title: "Vetting Repository",
    path: "/dashboard/vetting-repo/global",
    icon: getIcon(peopleFill),
  },
];

export default sidebarConfig;

export const contentRepoSidebarConfig = (ids) => {
  if (!Array.isArray(ids) || ids.length === 0) return null;

  const final = [];

  if (ids[0]) {
    final.push({
      path: "/dashboard/services",
      icon: getIcon(settingsfill),
      title: "Services",
    });
  }

  if (ids[1]) {
    final.push({
      path: "/dashboard/industry",
      icon: getIcon(settingsfill),
      title: "Industry",
    });
  }

  if (ids[2]) {
    final.push({
      path: "/dashboard/language",
      icon: getIcon(settingsfill),
      title: "Language",
    });
  }

  if (ids[3]) {
    final.push({
      title: "Tech Stack",
      path: "/dashboard/tech-stack",
      icon: getIcon(infofill),
    });
  }

  if (ids[4]) {
    final.push({
      title: "Tags",
      path: "/dashboard/tag",
      icon: getIcon(infofill),
    });
  }

  if (ids[5]) {
    final.push({
      title: "Category",
      path: "/dashboard/category",
      icon: getIcon(infofill),
    });
  }

  return {
    title: "Content Repository",
    path: "/dashboard/user",
    icon: getIcon(settingsfill),
    children: final.filter(Boolean), // Filter out falsy values
  };
};
export const vendorProfileSidebarConfig = {
  title: "Vendor Profiles",
  path: "/dashboard/profiles",
  icon: getIcon(peopleFill),
};
export const vendorShortlistSidebarConfig = {
  title: "Vendor Shortlist",
  path: "/dashboard/shortlist",
  icon: getIcon(searchFill),
};
export const supportSidebarConfig = {
  title: "Support",
  path: "/dashboard/contact",
  icon: getIcon(infofill),
};
export const financeApprovalSidebarConfig = {
  title: "Finance Approval",
  path: "/dashboard/financeApproval",
  icon: getIcon(currency),
};

export const raSidebarConfig = {
  title: "RA Leads",
  path: "/dashboard/ra",
  icon: getIcon(peopleFill),
};

// export const vettingRepoConfig = {
//   title: "Vetting Repository",
//   path: "/dashboard/vetting-repo",
//   icon: getIcon(peopleFill),
// };

// {
//   title: "Profile Approve",
//   path: "/dashboard/profile-approval",
//   icon: getIcon(currency),
// },

// akljsdfjlkjlaksdf
